import constants from './constants'

const utility = {
    getLines: (text) => {
        const lines = text?.trim?.()?.split(/\r?\n/)

        if (!lines) {
            return null
        }

        return lines.map((x, index) => {
            if (!x.trim()) {
                return ''
            }

            return (
                <div key={index} className="mb-2">
                    {x.trim()}
                </div>
            )
        })
    },

    getLocation: (guestDetails) => {
        let countryName = ''

        if (guestDetails?.country && guestDetails?.country !== 'US') {
            const country = constants.countries.find((x) => x.code === guestDetails?.country)

            if (country?.name) {
                countryName = country?.name
            }
        }

        const fields = [guestDetails?.city, guestDetails?.state, countryName]

        const outputFields = []

        for (let field of fields) {
            if (!field?.trim()) {
                continue
            }

            outputFields.push(field.trim())
        }

        return outputFields.join(', ')
    },

    async sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds))
    },
}

export default utility
