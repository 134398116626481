import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
//create
export const createReviewAction = createAsyncThunk(
    'comment/create',
    async (guestReview, { rejectWithValue, getState, dispatch }) => {
        //get user token
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        //http call
        try {
            const { data } = await axios.post(
                `/api/review`,
                {
                    review: guestReview?.review,
                    guestId: guestReview?.guestId,
                },
                config,
            )
            return data
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//fetch all posts
export const fetchReviewsAction = createAsyncThunk(
    'review',
    async (parameters, { rejectWithValue, getState, dispatch }) => {
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }

        const parametersString = new URLSearchParams(parameters).toString()

        try {
            const { data } = await axios.get(`/api/review?${parametersString}`, config)
            return data
        } catch (error) {
            if (!error?.response) throw error
            return rejectWithValue(error?.response?.data)
        }
    },
)

export const toggleAddLikesToPost = createAsyncThunk(
    'review/like',
    async (parameters, { rejectWithValue, getState, dispatch }) => {
        //get user token
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        try {
            const { data } = await axios.put(
                `/api/review/${parameters.type}`,
                { reviewId: parameters.reviewId, url: window.location.origin },
                config,
            )
            return data
        } catch (error) {
            if (!error?.response) throw error
            return rejectWithValue(error?.response?.data)
        }
    },
)

const commentSlices = createSlice({
    name: 'guestReview',
    initialState: {},
    extraReducers: (builder) => {
        builder.addCase(createReviewAction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createReviewAction.fulfilled, (state, action) => {
            state.loading = false
            state.commentCreated = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(createReviewAction.rejected, (state, action) => {
            state.loading = false
            state.commentCreated = undefined
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        builder.addCase(fetchReviewsAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(fetchReviewsAction.fulfilled, (state, action) => {
            state.loading = false
            state.reviews = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(fetchReviewsAction.rejected, (state, action) => {
            console.log(action.payload)
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //Likes
        builder.addCase(toggleAddLikesToPost.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(toggleAddLikesToPost.fulfilled, (state, action) => {
            state.likes = action?.payload
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(toggleAddLikesToPost.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })
    },
})

export default commentSlices.reducer
