import React from 'react'
import '../css/Latest.css'
import { fetchReviewsAction } from '../redux/slices/users/reviewSlices'
import * as Redux from 'react-redux'
import utility from '../utils/utility'
import DataTable from 'react-data-table-component'
import { Button, Modal } from 'react-bootstrap'

export const Latest2 = () => {
    const [modalText, setModalText] = React.useState('')
    const review = Redux.useSelector((state) => state?.review)
    const { reviews, loading } = review
    const dispatch = Redux.useDispatch()

    const columns = [
        {
            name: 'Guest Name',
            selector: (row) => row?.guest?.firstName,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Guest Location',
            selector: (row) => utility.getLocation(row?.guest),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Likes',
            selector: (row) => row?.likes?.length,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Created',
            selector: (row) => row.createdAt.slice(0, 10),
            sortable: true,
            width: '130px',
        },
        {
            name: 'Review',
            selector: (row) => (
                <div role="button" onClick={() => setModalText(row.review)}>
                    {row.review}
                </div>
            ),
            sortable: true,
            width: '450px',
        },
    ]

    React.useEffect(() => {
        dispatch(fetchReviewsAction({ limit: 100 }))
    }, [dispatch])

    return (
        <div className="container-fluid latest-container">
            <div className="l2">
                <h3>Recent Reviews</h3>
                <p className="text-muted my-2">Our most liked recent reviews:</p>
                <DataTable columns={columns} data={reviews} progressPending={loading} />
            </div>

            <Modal show={modalText !== ''} onHide={() => setModalText('')} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalText('')}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
