import React, { useEffect } from 'react'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import '../../css/GuestDetails.css'
import { fetchGuestDetailsAction } from '../../redux/slices/guests/createGuestSlice'
import { toggleAddLikesToPost } from '../../redux/slices/users/reviewSlices'
import utility from '../../utils/utility'
import CreateReview from '../post/CreateReview'
import ToastMessage from '../Toast'
import ReviewList from './ReviewList'

const GuestDetails = (props) => {
    const [message, setMessage] = React.useState('')
    const [editingReview, setEditingReview] = React.useState(null)
    const createReviewElement = React.useRef()
    const id = props.match.params.id
    const dispatch = useDispatch()
    const guest = useSelector((state) => state?.guest)
    const { guestDetails, loading, serverErr, appErr } = guest

    const review = useSelector((state) => state.review)
    const { commentCreated } = review

    useEffect(() => {
        dispatch(fetchGuestDetailsAction(id))
    }, [id, dispatch, commentCreated])

    const users = useSelector((state) => state?.users)
    const { userAuth } = users

    const onLike = (reviewId, type) => {
        dispatch(toggleAddLikesToPost({ reviewId, type }))

        if (type === 'likes') {
            setMessage('You liked this review')
        } else {
            setMessage('You reported this review. We will check the review and remove it if needed.')
        }
    }

    const onStartEditingReview = (review) => {
        setEditingReview(review)

        createReviewElement?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const onPostReview = () => {
        setEditingReview(null)
        dispatch(fetchGuestDetailsAction(id))
    }

    return (
        <div className="container add-review">
            <ToastMessage message={message} setMessage={setMessage} bg="success" />

            {appErr === 'limitReached' && (
                <div className="text-center my-4 pt-4">
                    You have reached the monthly view limit for your package. Upgrade to get more views.
                    <br />
                    <Link to={`/useraccount/${userAuth?._id}`} className="text-decoration-underline">
                        <button className="btn btn-dark mx-auto my-4">
                            <AiOutlineArrowRight className="mr-2 mb-1" />
                            Upgrade Now
                        </button>
                    </Link>
                </div>
            )}
            {(appErr || serverErr) && appErr !== 'limitReached' && <h5>Error {appErr}</h5>}

            {loading && (
                <div className="text-center my-5" style={{ minHeight: '100vh' }}>
                    <Spinner animation="border" role="status" className="mx-auto">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            {!loading && !appErr && !serverErr && (
                <div className="container">
                    <div className="my-3">
                        <div className="guest">
                            <h4>
                                {guestDetails?.firstName} {guestDetails?.lastName}
                            </h4>
                            <h5 className="text-muted">{utility.getLocation(guestDetails)}</h5>
                            <h5 className="text-muted">***-***-{guestDetails?.phoneNumber}</h5>

                            <div className="px-md-4">
                                <h5 className="mt-3 px-1">
                                    Reviews
                                    <span className="ms-4 text-muted" style={{ fontSize: '1.0rem' }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Number of positive reviews for this guest</Tooltip>}
                                        >
                                            <span>
                                                😀 {guestDetails?.review?.filter((x) => x.reviewType === '1').length}
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Number of negative reviews for this guest</Tooltip>}
                                        >
                                            <span className="ms-4">
                                                😡 {guestDetails?.review?.filter((x) => x.reviewType === '-1').length}
                                            </span>
                                        </OverlayTrigger>
                                    </span>
                                </h5>

                                <ReviewList
                                    review={guestDetails?.review}
                                    onLike={onLike}
                                    onStartEditingReview={onStartEditingReview}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5" ref={createReviewElement}>
                        <CreateReview guestId={id} onPostReview={onPostReview} editingReview={editingReview} />
                    </div>
                </div>
            )}
        </div>
    )
}
export default withRouter(GuestDetails)
