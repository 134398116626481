import React from 'react'
import { Spinner } from 'react-bootstrap'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Moment from 'react-moment'
import * as Redux from 'react-redux'
import { Link } from 'react-router-dom'
import '../css/Latest.css'
import { fetchReviewsAction } from '../redux/slices/users/reviewSlices'
import utility from '../utils/utility'

export const Latest = () => {
    const review = Redux.useSelector((state) => state?.review)
    const { reviews, loading } = review
    const dispatch = Redux.useDispatch()

    React.useEffect(() => {
        dispatch(fetchReviewsAction({ limit: 5 }))
    }, [dispatch])

    return (
        <div className="container-fluid latest-container">
            <div className="row">
                <div className="col-md-5 lg-left-intro">
                    <div className="heading">
                        <div
                            data-aos="fade-right"
                            data-aos-easing="ease-in"
                            data-aos-once="true"
                            data-aos-duration="250"
                        >
                            <h3>Recent Reviews</h3>
                            <p>Check out our most liked recent reviews!</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 lg-right-body">
                    <div className="col-md-12 review-box">
                        <div className="row">
                            {loading && (
                                <div className="text-center my-5">
                                    <Spinner animation="border" role="status" className="mx-auto">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}

                            {reviews?.map((review, index) => (
                                <div
                                    key={review?._id}
                                    className="col-md-12 border-white card my-1 review-text"
                                    data-aos="fade-left"
                                    data-aos-easing="ease-in"
                                    data-aos-once="true"
                                    data-aos-delay={200 * index}
                                >
                                    <div className="mt-3 text-muted ms-md-2">
                                        <p className="card-title fs-5">{review?.guest?.firstName}</p>
                                        <p className="card-subtitle mb-2 text-muted">
                                            {utility.getLocation(review?.guest)} <span className="mx-4"></span>{' '}
                                            <Moment format="MMM D, YYYY">{review?.createdAt}</Moment>
                                        </p>
                                    </div>

                                    <div className="col-lg-12">
                                        <figure>
                                            <blockquote className="otro-blockquote my-1">
                                                {utility.getLines(review?.review)}
                                            </blockquote>
                                        </figure>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="view-more-link mt-3 float-end">
                        <Link to="/view-more" className="btn btn-dark btn-lg main-button w-100" role="button">
                            <AiOutlineArrowRight className="mr-2 mb-1" />
                            View more
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
