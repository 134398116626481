import React from 'react'
import '../css/Faq.css'

const Faq = () => {
    return (
        <main>
            <div className="accordion-container">
                <h3>Frequently Asked Questions</h3>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item ">
                        <h2 className="accordion-header " id="flush-headingOne">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                What is Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse text-dark"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rental86 is a private and vetted review platform by vacation rental hosts, for vacation
                                rental hosts. Think of it as access to the renter-credit score for your potential
                                incoming guests, before they arrive.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThirteen">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThirteen"
                                aria-expanded="false"
                                aria-controls="flush-collapseThirteen"
                            >
                                Why do I need a service like Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThirteen"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThirteen"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                You, and people like you, are here to run a business. While we can be friendly, the
                                people we work with in our business are not our friends. The hosting platform is a
                                business, the guest is a client, and relationships with either of them can become
                                strained and quite problematic if we don't do our best to keep them happy.
                                <br />
                                <br /> Simple things, lies, small actions and sometimes misunderstandings can escalate
                                very quickly from a non-issue into a very real problem. Sometimes asking a guest to
                                replace a $25 broken vase can escalate into them being mad and reporting lies to the
                                hosting platform. This can quickly turn into you losing 90-100% of your business
                                depending on how you are structured. Rental86 allows you to keep the hosting platform,
                                and the guest happy while at the same time providing an outlet where you can report the
                                incident and make our community stronger. If you don't believe me, feel free to review
                                the unfortunate circumstances for some of the hosts here. (link to
                                https://www.airbnbhell.com/airbnb-host-stories/
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingEight">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseEight"
                                aria-expanded="false"
                                aria-controls="flush-collapseEight"
                            >
                                Will my information be made available to other members?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingEight"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No. Any postings that you post will be anonymous for other users of the platform. Your
                                information will be kept on file by Rental86, in case there are any technical or
                                content-related issues that come up related to your membership.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingNine">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseNine"
                                aria-expanded="false"
                                aria-controls="flush-collapseNine"
                            >
                                Will my review make a difference?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingNine"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                YES! Your review will make a difference, not just as a way for you to vent your
                                frustration, but also in helping others to avoid a negative experience with guests who
                                are cereal-offenders. Reviews are also weighted, so the more information that you
                                provide (videos / screenshots / police reports / damage photos etc..) then the higher up
                                at the top of the list your review will appear.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFour">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                            >
                                Is there a content policy related to postings on Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                YES. Rental86 is intended to be an “as close to factual” database as possible, and to be
                                used to protect yourself and thousands of other vacation rental owners around the world
                                from costly surprises. Negative opinion or bias-based statements will not be tolerated.
                                Algorithms are in place to detect and block biased-related speech to the best of our
                                ability, and as part of the community standards there are self-policing “report this
                                posting” buttons on every entry
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                How do I know which level I need?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Review the past few months of your bookings. Decide if you want to run this search on
                                every single guest, or just on the primary booking guest. Once you figure out the
                                average number of searches you wish to run each month, pick the membership level that
                                gives you the best number of searches for your needs.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                            >
                                Can any landlord join Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No. This database is intended to be exclusively used to vet vacation rental candidates,
                                not traditional tenants.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFive">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                            >
                                Is this publicly available?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No, only paying members who have been vetted and are confirmed fellow vacation rental
                                owners will have access to this information.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSix">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSix"
                                aria-expanded="false"
                                aria-controls="flush-collapseSix"
                            >
                                Can you only report negative surprises on Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingSix"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                OF COURSE NOT! Rental86, and the rest of the community loves hearing about a wonderful
                                renter that you might have had up front concerns about. Misconceptions and biases
                                influence human interaction daily, this database is intended to provide insight into
                                potential guests both good and bad
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwelve">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwelve"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwelve"
                            >
                                How do I write the best incident report?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwelve"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwelve"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                1) When writing an incident report, you want to abide by the four C’s: <br />
                                <ul>
                                    <li>Concise: Avoid overly wordy language. Keep statements simple and direct. </li>
                                    <li>
                                        Clear: Try to be as specific as possible. Avoid any vague statements or phrases.
                                    </li>
                                    <li>
                                        Correct / factual: Make sure that the information you include is accurate and
                                        unbiased.
                                    </li>
                                    <li>Complete: Include all relevant information.</li>
                                </ul>
                                2) The introduction should contain the incident date, incident time (approximate is ok),
                                type of incident (loud party / theft / illegal activity etc...), and narrative report.
                                <br />
                                <br />
                                The narrative should be written in paragraph form, and single spaced with a double space
                                between paragraphs. The introduction establishes the reason for your report and basic
                                circumstantial information.
                                <br />
                                <br />
                                3) Writing incident reports is different from writing traditional opinion pieces. This
                                type of writing requires its own rules and conventions. Certain basic guidelines
                                universally apply. Incident reports are typically written in first person, past tense
                                and organized in chronological order. <br />
                                Introduction:
                                <ul>
                                    <li>Date</li>
                                    <li>Time </li>
                                    <li>Why you were concerned </li>
                                    <li>Nature of the incident </li>
                                    <li>Location (no actual addresses please) </li>
                                </ul>
                                Best Practices: <br />
                                <li>Write in active voice as much as possible </li>
                                <li>Be direct, concrete, and concise </li>
                                <li>Use direct quotes only when needed </li>
                                <li>Use a person’s name if it is known in this format: "Abraham L." </li>
                                <li>When the person's name is not known, use "unknown guest 1 / 2 / 3 etc...". </li>
                                <li>
                                    Avoid slang, swearing, sexist, biased, and insensitive language unless quoting
                                    someone else’s words
                                </li>
                                <li>
                                    Avoid calling people names, provide the details of why you would call them that.
                                    Instead of saying Mr. L was a thief, say "When Mr. L checked out, we did an
                                    inventory of the unit as usual, the bluetooth speaker and kitchen blender were
                                    missing".
                                </li>
                                <br />
                                4) Upload supporting evidence:
                                <br />
                                <li>Videos of the interaction</li>
                                <li>Security videos of the 13 extra guests leaving</li>
                                <li>
                                    A screenshot of the message where you sent Mr. Lincoln the reminder on house rules.
                                </li>
                                <li>Anything else that would strengthen your incident report.</li>
                                <br />
                                <br />
                                Incident Report Example:
                                <br />
                                <br />
                                <i>
                                    "On 05/15/2021 at approximately 2200 hours, I was disturbed in my home by loud
                                    thumping bass music. I walked outside to investigate where sound was coming from. I
                                    approached the mother in law unit that we rent out for vacation rentals, it was
                                    currently rented by guest Abraham L. As it was past the agreed upon quiet hours, I
                                    knocked on the door as the bass music was coming from inside.
                                    <br />
                                    <br />
                                    Mr. L opened the door, there were 18 people inside. He had only reserved for 5
                                    people, and the house rules indicate no parties are allowed. When he opened the
                                    door, it was clear that several of the guests were smoking in the unit, which was
                                    another house rule violation and I smelled at least two different types of smoke. I
                                    asked Mr. L to have his additional guests leave, and I let him know that our
                                    neighbors would often call the police on anyone making noise in the area. It did not
                                    appear that any physical damage to the unit or belongings had occurred.
                                    <br />
                                    <br />
                                    Mr. L quickly cooperated to have the additional guests leave. I also reminded Mr. L
                                    that smoking inside the unit was a violation of the house rules, he apologized and
                                    promised that any further smoking would be done outside. I thanked him for his
                                    cooperation, and let him know that while I understand small get togethers can
                                    sometimes get out of hand, any further behavior of this type would not be tolerated
                                    and the police would be called if it happened again. I let him know that I would not
                                    hold that incident against him, and to please abide by the house rules moving
                                    forward. I texted Mr. L a link to my listing, showing the house rules he had agreed
                                    to.
                                    <br />
                                    <br />
                                    He thanked me, and I left. When he vacated the unit, it was clean and tidy. He later
                                    left me a positive review, and I did the same for him." Then upload any supporting
                                    evidence: - Videos of the interaction - Security videos of the 13 extra guests
                                    leaving - A screenshot of the message where you sent Mr. Lincoln the reminder on
                                    house rules. - Anything else that would strengthen your incident report.""
                                </i>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSeven"
                                aria-expanded="false"
                                aria-controls="flush-collapseSeven"
                            >
                                What kind of verification does Rental86 do?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingSeven"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Every member of the platform is required to provide a government ID, a link to their
                                vacation rental unit, a current insurance coverage statement for that premises, and any
                                additional documentation needed to link their government ID name to the ownership to
                                that property.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTen">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTen"
                                aria-expanded="false"
                                aria-controls="flush-collapseTen"
                            >
                                What are the benefits of each membership level?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTen"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                All membership levels come with access to a certain number of searches (30 / 150 /
                                unlimited), and access to professional scripts and strategies on how to negotiate rental
                                cancellations when a problem arrives. Everything is intended to give you the best
                                opportunity available to make judgement calls before the guest arrives, and before they
                                can leave you a review. The Pro level membership also comes with a user-agreement, and
                                access to a high-def banner that can be added to your webpage, showing your vacation
                                rental management clients that you go above and beyond to protect their interests and
                                their property.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingEleven">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseEleven"
                                aria-expanded="false"
                                aria-controls="flush-collapseEleven"
                            >
                                How many members are a part of Rental86?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseEleven"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingEleven"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                With over 4,000 members, and membership growing daily we already have tens of thousands
                                of incidents reported. We don’t have information on every guest, but we hope to point
                                out the important ones!!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Faq
