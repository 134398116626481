import React, { useState, Fragment, useContext } from 'react'
import '../../../css/Navbar.css'
import { Link } from 'react-router-dom'

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'About', href: '/learnmore', current: false },
    { name: 'Pricing', href: '/selectplan', current: false },
    { name: 'FAQ', href: '/faq', current: false },
    { name: 'Contact', href: '/contact', current: false },
    { name: 'Login', href: '/login', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PublicNavbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <a className="navbar-brand" aria-current="page" href="/">
                    <img src={require('../../../img/logo.png')} alt="logo" width={55} />
                    Rental86
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse justify-content-end " id="navbarNav">
                    <div>
                        <ul>
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-900 text-black'
                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        '  px-3 py-2 rounded-md text-sm font-medium',
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <li className="link-to-pages"> {item.name}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default PublicNavbar
