import React from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import utility from '../../utils/utility'
import { FaPaperclip } from 'react-icons/fa'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function ReviewList({ review, onLike, onStartEditingReview, allReviewsByUser }) {
    const [reviewForVersions, setReviewForVersions] = React.useState(null)
    const users = useSelector((state) => state?.users)
    const { userAuth } = users

    if (!review?.length) {
        if (allReviewsByUser) {
            return <h5 className="text-center text-muted mt-4">Reviews you post will appear here</h5>
        }

        return <h5 className="text-center text-muted">No reviews for this guest yet</h5>
    }

    if (!review?.[0]?.id) {
        return null
    }

    function getFiles(review) {
        if (!review?.files?.length) {
            return null
        }

        const documents = review?.files?.map((x, index) => (
            <a key={x} href={x} className="mx-1" target="_blank" rel="noreferrer">
                {x.endsWith('.pdf') && `Link ${index + 1}`}
                {!x.endsWith('.pdf') && <img src={x} alt="file" className="me-1" style={{ height: 200 }} />}
            </a>
        ))

        return documents
    }

    let list = review?.slice(0)

    if (!allReviewsByUser) {
        list = list?.reverse()
    }

    return (
        <>
            <Modal show={reviewForVersions} onHide={() => setReviewForVersions(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Review Edit History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {reviewForVersions?.versions?.map((version, index) => (
                        <div className="mb-4">
                            <strong>Version {index + 1}:</strong>
                            {utility.getLines(version)}
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setReviewForVersions(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {list.map((review) => (
                <div key={review.id} className="container border rounded py-2 my-2">
                    {allReviewsByUser && (
                        <Link to={`/guest/${review.guest?._id}`} className="">
                            <div className="mt-1 mb-2">
                                <span className="h4">{review?.guest?.firstName + ' ' + review?.guest?.lastName} </span>
                                <span className="ms-2 text-muted">{utility.getLocation(review?.guest)}</span>
                            </div>
                        </Link>
                    )}
                    <div className="text-sm font-medium text-green-400 mb-2">{utility.getLines(review?.review)}</div>
                    <div className="text-sm font-medium text-muted">
                        <Moment format="MMM D YYYY" className="me-4">
                            {review?.createdAt}
                        </Moment>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip-2">
                                    {review?.reviewType === '-1' && 'Negative'}
                                    {review?.reviewType === '1' && 'Positive'} review
                                </Tooltip>
                            }
                        >
                            <span>
                                {review?.reviewType === '1' && <span className="text-success">😀</span>}
                                {review?.reviewType === '-1' && <span className="text-danger">😡</span>}
                            </span>
                        </OverlayTrigger>
                        {review?.versions?.length > 0 && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip-2">See original review</Tooltip>}
                            >
                                <span className="ms-4" role="button" onClick={() => setReviewForVersions(review)}>
                                    Edited
                                </span>
                            </OverlayTrigger>
                        )}
                    </div>
                    <div className="icons mt-2">
                        <div className="d-inline" role="button">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip-2">Like this review</Tooltip>}
                            >
                                <box-icon
                                    name="like"
                                    animation="tada-hover"
                                    color="#6dad5e"
                                    onClick={() => onLike?.(review?._id, 'likes')}
                                ></box-icon>
                            </OverlayTrigger>

                            <div className="likes-num align-top ms-1">{review?.likes?.length}</div>
                        </div>

                        <div className="red-flag">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip-2">Report this review</Tooltip>}
                            >
                                <box-icon
                                    name="flag"
                                    animation="tada-hover"
                                    color="#c35252"
                                    onClick={() => onLike?.(review?._id, 'dislikes')}
                                ></box-icon>
                            </OverlayTrigger>
                        </div>

                        {review?.user === userAuth?._id && (
                            <div className="red-flag ms-5">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Edit your review</Tooltip>}
                                >
                                    <box-icon
                                        name="pencil"
                                        color="black"
                                        onClick={() => onStartEditingReview(review)}
                                    ></box-icon>
                                </OverlayTrigger>
                            </div>
                        )}
                    </div>
                    {review?.files?.length > 0 && (
                        <div className="my-2" style={{ fontSize: '1.15rem' }}>
                            <FaPaperclip className="text-muted" /> Attachments
                        </div>
                    )}
                    <div>{getFiles(review)}</div>
                </div>
            ))}
        </>
    )
}
