import React, { useState, Fragment, useContext } from 'react'
import '../../../css/Navbar.css'
import { Link } from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PrivateNavbar = ({ isLogin }) => {
    const navigation = [
        { name: 'Search Guests', href: '/guestlist', current: false },
        { name: 'About', href: '/learnmore', current: false },
        { name: 'FAQ', href: '/faq', current: false },
        { name: 'Hosting Help', href: '/bestpractices', current: false },
    ]

    if (isLogin?.isAdmin) {
        navigation.push({ name: 'Admin Dashboard', href: '/admin-dashboard', current: true })
    }

    const userNavigation = [
        {
            name: 'Contact',
            href: `/contact`,
            current: false,
        },
        { name: 'Account', href: `/useraccount/${isLogin?._id}`, current: false },
    ]
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid ">
                <Link className="navbar-brand" aria-current="page" to="/memberhomepage">
                    <img src={require('../../../img/logo.png')} alt="logo" width={55} />
                    Rental86
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse justify-content-end " id="navbarNav">
                    <div>
                        <ul>
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        item.current ? ' text-black' : ' ',
                                        '  px-3 py-2  font-medium',
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <li className="link-to-pages"> {item.name}</li>
                                </Link>
                            ))}
                            {userNavigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(item.current ? ' ' : ' ', '  p-3 py-2  font-medium')}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <li className="link-to-pages"> {item.name}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default PrivateNavbar
