import React from 'react'
import '../css/UserReview.css'

const Testimonials = () => {
    return (
        <div className="container-fluid user-review-container">
            <div className="row">
                <div className="col-md-5 left-col"></div>
                <div className="col-md-6 user-reviews-box">
                    <div className="row">
                        <h3 data-aos="fade-up" data-aos-delay="100">
                            What People Are Saying
                        </h3>

                        <div className="col-md-12 user-review" data-aos="fade-up" data-aos-delay="200">
                            <figure>
                                <blockquote className="blockquote">
                                    <p>Testimonial coming soon</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">Coming soon</figcaption>
                            </figure>
                        </div>

                        <div className="col-md-12 user-review" data-aos="fade-up" data-aos-delay="300">
                            <figure>
                                <blockquote className="blockquote">
                                    <p>Testimonial coming soon</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">Coming soon</figcaption>
                            </figure>
                        </div>

                        <div className="col-md-12 user-review" data-aos="fade-up" data-aos-delay="300">
                            <figure>
                                <blockquote className="blockquote">
                                    <p>Testimonial coming soon</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">Coming soon</figcaption>
                            </figure>
                        </div>

                        <div className="col-md-12 user-review" data-aos="fade-up" data-aos-delay="300">
                            <figure>
                                <blockquote className="blockquote">
                                    <p>Testimonial coming soon</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">Coming soon</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
