import { configureStore } from '@reduxjs/toolkit'
import usersReducer from '../slices/users/usersSlices'
import guestReducer from '../slices/guests/createGuestSlice'
import reviewReducer from '../slices/users/reviewSlices'
import sendMail from '../slices/email/sendMailSlice'
const store = configureStore({
    reducer: {
        users: usersReducer,
        guest: guestReducer,
        review: reviewReducer,
        email: sendMail,
    },
})

export default store
