import React from 'react'
// import "../css/Bestpractices.css";

const HostingHelp = () => {
    return (
        <main>
            <div className="accordion-container">
                <h3>Hosting Best Practices</h3>
                <div className="best-practice-subtitle">
                    {' '}
                    <p>
                        * If you decide to include these as part of your screening process,
                        <br /> post them in your house rules section, so they can be seen before a guest books.{' '}
                    </p>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item ">
                        <h2 className="accordion-header " id="flush-headingOne">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                Insist on receiving a copy of EVERYONE’s government ID (except for children of course).
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse text-dark"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                While you can set your hosting requirements on many vacation rental platforms, to only
                                allow guests with valid government ID – it doesn’t really help if you don’t have a copy
                                of it, and something goes truly wrong. Not only that, the platform only requires the
                                primary guest to provide an ID, any random person can come with them and be entirely
                                untraceable. Without a copy of an ID, you have no knowledge of where they live, what
                                their actual name is, you have no way to serve them legal papers or to pursue them when
                                something goes wrong.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                Have all guests provide their Covid vaccine card, or a negative Covid test.
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                This is currently a requirement for many vacation rentals around the world. To protect
                                you, your cleaners, and future guests, you should insist on these being provided. As you
                                are unable to confirm who’s test it is without having photographic and validated
                                information to back it up – it should also be accompanied by a copy of their government
                                ID.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSix">
                            <button
                                className="accordion-button collapsed bg-transparent text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSix"
                                aria-expanded="false"
                                aria-controls="flush-collapseSix"
                            >
                                Check all guests against the R86 database.
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingSix"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                There is a reason that you pay to have a membership here, be sure to use it. This site
                                is intended to provide you an insight into guests and potential future problems that you
                                won’t be able to get anywhere else.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default HostingHelp
