import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import axios from 'axios'
import utility from '../../../utils/utility'

const resetPasswordAction = createAction('password/reset')
const resetUserAction = createAction('user/profile/reset')
//----------------------------------------------------------------
//REGISTER USER ACTION
//----------------------------------------------------------------
export const registerUserAction = createAsyncThunk(
    'users/register',
    async (user, { rejectWithValue, getState, dispatch }) => {
        await utility.sleep(0)
        localStorage.setItem('userInfo', JSON.stringify(user))
        return user
    },
)

//----------------------------------------------------------------
//LOGIN USER ACTION
//----------------------------------------------------------------
export const loginUserAction = createAsyncThunk(
    'user/login',
    async (userData, { rejectWithValue, getState, dispatch }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        try {
            const { data } = await axios.post(`/api/users/login`, userData, config)
            //save user information  to local storage
            localStorage.setItem('userInfo', JSON.stringify(data))
            return data
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//----------------------------------------------------------------
//LOGOUT USER ACTION
//----------------------------------------------------------------
export const logoutAction = createAsyncThunk(
    '/user/logout',
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            localStorage.removeItem('userInfo')
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//update profile information action
export const updateUserAction = createAsyncThunk(
    'users/update',
    async (userData, { rejectWithValue, getState, dispatch }) => {
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        try {
            const { data } = await axios.put(
                `/api/users`,
                {
                    firstName: userData?.firstName,
                    lastName: userData?.lastName,
                    email: userData?.email,
                    phone: userData?.phone,
                    city: userData?.city,
                    state: userData?.state,
                    zipcode: userData?.zipcode,
                    address: userData?.address,
                    address2: userData?.address2,
                    propertyListing: userData?.propertyListing,
                },
                config,
            )
            dispatch(resetUserAction())
            return data
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//Update users password
export const updatePasswordAction = createAsyncThunk(
    'password/update',
    async (password, { rejectWithValue, getState, dispatch }) => {
        //get user token
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        //http call
        try {
            const { data } = await axios.put(
                `/api/users/password`,
                {
                    password,
                },
                config,
            )
            //dispatch
            dispatch(resetPasswordAction())
            return data
        } catch (error) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//----------------------------------------------------------------
// FETCH USER DETAILS
//----------------------------------------------------------------
export const fetchUserDetailsAction = createAsyncThunk(
    'user/detail',
    async (id, { rejectWithValue, getState, dispatch }) => {
        const user = getState()?.users
        const { userAuth } = user

        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }

        if (!id) {
            id = userAuth?._id
        }

        try {
            const { data } = await axios.get(`/api/users/${id}`, config)
            return data
        } catch (error) {
            if (!error?.response) throw error
            return rejectWithValue(error?.response.data)
        }
    },
)

//Generate password reset token
export const passwordResetTokenAction = createAsyncThunk(
    'password/token',
    async (email, { rejectWithValue, getState, dispatch }) => {
        try {
            const body = { email, url: window.location.origin }
            //http call
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(`/api/users/forgot-password-token`, body, config)
            return data
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

export const passwordResetAction = createAsyncThunk(
    'password/reset',
    async (user, { rejectWithValue, getState, dispatch }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        //http call
        try {
            const { data } = await axios.put(
                `/api/users/reset-password`,
                { password: user?.password, token: user?.token },
                config,
            )
            return data
        } catch (error) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

//get user information from local storage
const userLoginFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

//slices
const usersSlices = createSlice({
    name: 'users',
    initialState: {
        userAuth: userLoginFromStorage,
    },
    extraReducers: (builder) => {
        //register
        builder.addCase(registerUserAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(registerUserAction.fulfilled, (state, action) => {
            state.loading = false
            state.userAuth = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(registerUserAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //login
        builder.addCase(loginUserAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })

        builder.addCase(loginUserAction.fulfilled, (state, action) => {
            state.userAuth = action?.payload
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(loginUserAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //user details
        builder.addCase(fetchUserDetailsAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(fetchUserDetailsAction.fulfilled, (state, action) => {
            state.loading = false
            state.userDetails = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(fetchUserDetailsAction.rejected, (state, action) => {
            console.log(action.payload)
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //----------------------------------------------------------------
        // LOGOUT USER ACTION
        //----------------------------------------------------------------
        builder.addCase(logoutAction.pending, (state, action) => {
            state.loading = false
        })

        builder.addCase(logoutAction.fulfilled, (state, action) => {
            state.userAuth = undefined
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })

        builder.addCase(logoutAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //update user profile
        builder.addCase(updateUserAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(resetUserAction, (state, action) => {
            state.isUpdated = true
        })
        builder.addCase(updateUserAction.fulfilled, (state, action) => {
            state.loading = false
            state.userUpdated = action?.payload
            state.isUpdated = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(updateUserAction.rejected, (state, action) => {
            console.log(action.payload)
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //update password
        builder.addCase(updatePasswordAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(resetPasswordAction, (state, action) => {
            state.isUpdated = true
        })
        builder.addCase(updatePasswordAction.fulfilled, (state, action) => {
            state.loading = false
            state.passwordUpdated = action?.payload
            state.isUpdated = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(updatePasswordAction.rejected, (state, action) => {
            console.log(action.payload)
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //generate password reset token
        builder.addCase(passwordResetTokenAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(passwordResetTokenAction.fulfilled, (state, action) => {
            state.loading = false
            state.passwordToken = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(passwordResetTokenAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //Password reset
        builder.addCase(passwordResetAction.pending, (state, action) => {
            state.loading = true
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(passwordResetAction.fulfilled, (state, action) => {
            state.loading = false
            state.passwordReset = action?.payload
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(passwordResetAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })
    },
})

export default usersSlices.reducer
