import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import '../../css/ResetPasswordForm.css'
import { useDispatch, useSelector } from 'react-redux'
import { passwordResetTokenAction } from '../../redux/slices/users/usersSlices'

const Result = () => {
    return (
        <div className="result-sent mt-2">
            <p>Please check your email for further instructions.</p>
        </div>
    )
}

//Form schema
const formSchema = Yup.object({
    email: Yup.string().required('Email is required'),
})

const ResetPasswordForm = () => {
    const [result, showResult] = useState(false)
    const dispatch = useDispatch()
    //formik
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            //dispath the action
            dispatch(passwordResetTokenAction(values?.email))
            showResult(true)
            setTimeout(() => {
                showResult(false)
            }, 4000)
        },
        validationSchema: formSchema,
    })

    setTimeout(() => {
        showResult(false)
    }, 4000)

    //select data from store
    const users = useSelector((state) => state?.users)
    const { passwordToken, loading, appErr, serverErr } = users
    return (
        <div className="reset-password-form">
            <div className=" container reset-form">
                <div className="reset-password-title text-center">
                    <h3>Let's reset your password</h3>
                    <p>
                        Enter your email associated with your account and we'll email instructions for setting a new
                        one.
                    </p>{' '}
                </div>

                <form onSubmit={formik.handleSubmit}>
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                    <div className="form-group reset">
                        {' '}
                        <label for="email">Email address</label>
                        <input
                            type="email"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange('email')}
                            onBlur={formik.handleBlur('email')}
                            className="form-control"
                            placeholder="Email address"
                        />
                        <div className="text-red">{formik.touched.email && formik.errors.email}</div>
                    </div>

                    <div className="error-message text-center">{appErr || serverErr ? appErr || serverErr : null}</div>
                    <div className="success-message result-sent text-center">
                        {passwordToken && result ? <Result /> : null}
                    </div>
                    <div>
                        {loading ? (
                            <button disabled className="btn btn-dark">
                                Loading...
                            </button>
                        ) : (
                            <div className="d-grid reset-button-container">
                                <button type="submit" className="btn btn-lg main-button btn-dark">
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPasswordForm
