//------------------------------------
// KEEP THIS
//------------------------------------

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const createGuestAction = createAsyncThunk(
    'guest/create',
    async (guest, { rejectWithValue, getState, dispatch }) => {
        //get the user token
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        try {
            const { data } = await axios.post(`/api/guest`, guest, config)
            return data
        } catch (error) {
            if (!error?.response) throw error

            return rejectWithValue(error?.response?.data)
        }
    },
)

export const searchGuestsAction = createAsyncThunk(
    'guest/search',
    async (searchFilters, { rejectWithValue, getState, dispatch }) => {
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }

        try {
            const { data } = await axios.post('/api/guest/search', searchFilters, config)
            return data
        } catch (error) {
            if (!error?.response) {
                throw error
            }
            return rejectWithValue(error?.response?.data)
        }
    },
)

export const fetchGuestDetailsAction = createAsyncThunk(
    'guest/detail',
    async (id, { rejectWithValue, getState, dispatch }) => {
        //  get user token
        const user = getState()?.users
        const { userAuth } = user
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }
        try {
            const { data } = await axios.get(`/api/guest/${id}`, config)
            return data
        } catch (error) {
            if (!error?.response) throw error
            return rejectWithValue(error?.response?.data)
        }
    },
)
const guestSlices = createSlice({
    name: 'guest',
    initialState: {},
    extraReducers: (builder) => {
        builder.addCase(createGuestAction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createGuestAction.fulfilled, (state, action) => {
            state.guestCreated = action?.payload
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(createGuestAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        builder.addCase(searchGuestsAction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(searchGuestsAction.fulfilled, (state, action) => {
            state.guestList = action?.payload
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(searchGuestsAction.rejected, (state, action) => {
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })

        //----------------------------------------------------------------
        // FETCH guest details
        //----------------------------------------------------------------
        builder.addCase(fetchGuestDetailsAction.pending, (state, action) => {
            state.guestDetails = {}
            state.loading = true
        })
        builder.addCase(fetchGuestDetailsAction.fulfilled, (state, action) => {
            state.guestDetails = action?.payload
            state.loading = false
            state.appErr = undefined
            state.serverErr = undefined
        })
        builder.addCase(fetchGuestDetailsAction.rejected, (state, action) => {
            state.guestDetails = {}
            state.loading = false
            state.appErr = action?.payload?.message
            state.serverErr = action?.error?.message
        })
    },
})

export default guestSlices.reducer
