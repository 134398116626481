import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import React from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { registerUserAction } from '../../redux/slices/users/usersSlices'
import constants from '../../utils/constants'

export default function CheckoutForm(props) {
    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState(null)
    const [tier] = React.useState(getTier)
    const dispatch = useDispatch()
    const history = useHistory()
    const storeData = useSelector((store) => store?.users)
    const { userAuth } = storeData

    function getTier() {
        const tierId = localStorage.getItem('tierId')
        return constants.tiers.find((x) => x.id === tierId)
    }

    const paymentElementOptions = {
        layout: 'tabs',
    }

    const createSubscription = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }

        const { data } = await axios.post(`/api/users/create-subscription`, {}, config)

        if (data) {
            //updates userAuth in redux
            dispatch(registerUserAction(data))
        }

        return data?._id
    }

    const confirmPayment = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setLoading(true)

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.origin + '/memberhomepage',
            },
            redirect: 'if_required',
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (response?.error?.message) {
            setMessage(response?.error?.message)
        } else {
            setMessage('Payment successful. Going to member area...')
            const success = await createSubscription()

            if (success) {
                localStorage.removeItem('tierId')
                localStorage.removeItem('couponCode')

                history.push('/memberhomepage')
            }
        }

        setLoading(false)
    }

    const getPriceString = () => {
        const parameters = new URLSearchParams(window.location.search)

        const coupon = parameters.get('coupon') || localStorage.getItem('couponCode')

        if (coupon) {
            return `$1 (then ${tier?.priceString} / year, starting one year from now)`
        }

        return tier?.priceString
    }

    return (
        <div className="row g-3">
            <div className="thankyou-message">
                <h4>Thanks for signing up!</h4>
                <h5>Enter your payment information to continue</h5>
            </div>

            <PaymentElement id="payment-element" options={paymentElementOptions} />

            <Alert variant="primary" className="mb-1">
                <div className="fw-bold fs-5">{tier?.name} Plan</div>
                <div className="text-muted mt-2">Billed yearly</div>
                <div className="mt-0">
                    Total due today: <span className="fw-bold fs-4 ms-2">{getPriceString()}</span>
                </div>
            </Alert>

            <button
                onClick={confirmPayment}
                className="btn btn-lg btn-dark main-button"
                disabled={loading || !stripe || !elements}
            >
                {loading ? 'Loading...' : 'Subscribe'}
            </button>

            {/* Show any error or success messages */}
            <Alert variant="danger" style={{ opacity: message ? 1 : 0 }}>
                <div className="text-center text-danger fw-bold">{message}</div>
            </Alert>
        </div>
    )
}
