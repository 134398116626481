import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Redirect } from 'react-router-dom'
import '../../css/Login.css'
import { loginUserAction } from '../../redux/slices/users/usersSlices'

const formSchema = Yup.object({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
})
const Loginform = () => {
    const dispatch = useDispatch()

    const [passwordShown, setPasswordShown] = useState(false)
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown)
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: (values) => {
            dispatch(loginUserAction(values))
        },
        validationSchema: formSchema,
    })

    //redirect after user is logged in
    const store = useSelector((state) => state?.users)
    const { userAuth, loading, serverErr, appErr } = store
    if (userAuth) return <Redirect to="/memberhomepage" />

    return (
        <div className="container-fluid login-form-container">
            <div className="col-md-7 login-form">
                <div className="login-header px-2">
                    <h3>Welcome back!</h3>
                    <h5 className="mt-3 ms-2">Please login to your account</h5>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-md-12 user-input">
                        <span className="form-label">Email</span>
                        <input
                            label="Email"
                            type="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange('email')}
                            onBlur={formik.handleBlur('email')}
                            placeholder="Email"
                        />
                        <div className="text-red">{formik.touched.email && formik.errors.email}</div>
                    </div>
                    <div className="col-md-12 user-input">
                        <span className="form-label">Password</span>
                        <Link className="reset-password-link" to="/password-reset">
                            Reset Password
                        </Link>
                        <input
                            label="Password"
                            type={passwordShown ? 'text' : 'password'}
                            className="form-control"
                            value={formik.values.password}
                            onChange={formik.handleChange('password')}
                            onBlur={formik.handleBlur('password')}
                            placeholder="Password"
                        />
                        <button className="show-pass" type="button" onClick={togglePassword}>
                            <small>SHOW PASSWORD</small>
                        </button>
                        <div className="text-red">{formik.touched.password && formik.errors.password}</div>
                    </div>
                    <div className="error-message text-center">{appErr || serverErr ? appErr || serverErr : null}</div>
                    <div className="d-grid col-md-12">
                        {loading ? (
                            <button disabled className=" btn btn-dark main-button mt-3">
                                Loading...
                            </button>
                        ) : (
                            <button type="submit" className=" btn btn-dark main-button btn-lg mt-3">
                                Sign in
                            </button>
                        )}
                    </div>
                </form>
                <div className="loginform-link-signup px-3">
                    Not a member?{' '}
                    <Link className="signup-link text-decoration-underline" to="/selectplan">
                        Join Now!
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Loginform
