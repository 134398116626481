import React from 'react'

const UploadDocuments = (props) => {
    let formik = props.formik

    return (
        <>
            <h4 className="mt-4">Upload Documents</h4>
            <div className="col-12 mb-2">
                To protect both you and other members of the community, we need documents to establish a current
                ownership interest between you and the property that you own or manage. We require:
                <ul className="mt-2 ms-3">
                    <li>- Your government ID</li>
                    <li>- The URL and physical address of your listing(s)</li>
                    <li>- A current insurance declaration page showing your name and the address of the property</li>
                </ul>
                Verification takes 1 to 3 business days. We might need more documents if for example, the unit is owned
                by a company. Then we'd also need the state LLC registration which shows you are a full or partial owner
                of that company.
            </div>

            <div className="col-md-6">
                <span className="form-label">Upload Government ID</span>
                <input
                    type="file"
                    className="form-control"
                    name="documents"
                    required
                    multiple
                    accept=".gif,.jpg,.jpeg,.png,.pdf"
                />
            </div>

            <div className="col-md-6">
                <span className="form-label">Upload Insurance Declaration</span>
                <input
                    type="file"
                    className="form-control"
                    name="insuranceDocuments"
                    required
                    multiple
                    accept=".gif,.jpg,.jpeg,.png,.pdf"
                />
            </div>

            <div className="col-md-6">
                <span className="form-label">Upload Other Documents (optional)</span>
                <input
                    type="file"
                    className="form-control"
                    name="otherDocuments"
                    multiple
                    accept=".gif,.jpg,.jpeg,.png,.pdf"
                />
            </div>

            <div className="col-md-6">
                <span className="form-label">Insurance expiration date</span>
                <input
                    value={formik?.values.insuranceExpirationDate}
                    onChange={formik?.handleChange('insuranceExpirationDate')}
                    onBlur={formik?.handleBlur('insuranceExpirationDate')}
                    type="date"
                    name="insuranceExpirationDate"
                    className="form-control"
                />
                <div className="text-red">
                    {formik?.touched.insuranceExpirationDate && formik?.errors.insuranceExpirationDate}
                </div>
            </div>

            <label className="form-label">
                Choose only images or PDF files. You can select multiple files in the file selection dialog.
            </label>
        </>
    )
}

export default UploadDocuments
