export const getQuotaString = (tierId) => {
    const quotas = {
        low: 30,
        mid: 200,
        high: 2000,
        highest: -1,
    }

    let result = quotas[tierId]

    if (result === -1) {
        result = 'unlimited'
    }

    return result
}
