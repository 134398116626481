import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import constants from '../utils/constants'

function PriceCards() {
    const history = useHistory()

    const handleClick = async (price) => {
        localStorage.setItem('tierId', price.id)
        history.push('/register')
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs="12" className="mb-2">
                    <h3 className="text-center my-4" data-aos="fade" data-aos-delay="100">
                        Plans for your now and a bigger brighter future
                    </h3>
                </Col>
                {constants.tiers.map((tier, index) => (
                    <Col key={tier.id} md="3">
                        <Card className="mb-4 border-0" data-aos="fade-up" data-aos-delay={200 * index}>
                            <Card.Body>
                                <Card.Title className="text-uppercase">{tier.name}</Card.Title>
                                <Card.Title className="my-4">
                                    {tier.priceString} <small className="text-muted">per year</small>
                                </Card.Title>
                                <div>
                                    <div className="d-grid gap-2 my-4">
                                        <Button variant="outline-dark" size="lg" onClick={() => handleClick(tier)}>
                                            Subscribe
                                        </Button>
                                    </div>
                                    <ul>
                                        {tier.features.map((feature) => (
                                            <li
                                                className="mt-2"
                                                key={feature}
                                                style={{ paddingLeft: '1.5rem', textIndent: '-1.5rem' }}
                                            >
                                                <AiFillCheckCircle
                                                    className="mr-2"
                                                    color="grey"
                                                    style={{ marginBottom: 2 }}
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
export default PriceCards
