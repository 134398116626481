import React from 'react'
import { useSelector } from 'react-redux'
import PublicNavbar from './public/PublicNavbar'
import PrivateNavbar from './private/PrivateNavbar'

const Nav = () => {
    const state = useSelector((state) => state.users)
    const { userAuth } = state

    return <>{userAuth ? <PrivateNavbar isLogin={userAuth} /> : <PublicNavbar />}</>
}

export default Nav
