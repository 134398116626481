import React from 'react'
import '../../css/CreateReview.css'
import CreateReview from '../post/CreateReview'

const AddGuestReview = () => {
    return (
        <div className="container rg-container  ">
            <div className="container review-guest-container  ">
                <CreateReview createGuest={true} guestId={''} />
            </div>
        </div>
    )
}
export default AddGuestReview
