import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import '../css/Contact.css'
import { sendMailAction } from '../redux/slices/email/sendMailSlice'

const formSchema = Yup.object({
    subject: Yup.string(),
    message: Yup.string().required('Message is required'),
    email: Yup.string().required('Email is required'),
})

export default function Contact() {
    const [disableButton, setDisableButton] = React.useState(false)
    const dispatch = useDispatch()
    const users = useSelector((state) => state?.users)
    const { userAuth } = users
    const email = useSelector((state) => state?.email)
    const { mailSent, loading, appErr, serverErr } = email

    const formik = useFormik({
        initialValues: {
            email: userAuth?.email,
            subject: 'New support message',
            message: '',
        },
        onSubmit: (values) => {
            setDisableButton(true)

            const body = {
                userId: userAuth?._id,
                subject: values.subject,
                message: `<p>Email address: ${values.email}</p><p>Message:</p><p>${values.message}</p>`,
            }

            dispatch(sendMailAction(body))

            setTimeout(() => {
                setDisableButton(false)
            }, 6000)
        },
        validationSchema: formSchema,
    })

    return (
        <div className="container admin-contact" style={{ minHeight: '70vh' }}>
            <h4 className="admin-contact-title">Questions or concerns? Send us a message!</h4>
            <form className="row" onSubmit={formik.handleSubmit}>
                <div className="form-group row g-2 mt-2 create-account">
                    <div className="col-md-12">
                        <span className="form-label">Your email address</span>
                        <input
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange('email')}
                            onBlur={formik.handleBlur('email')}
                            className="form-control"
                        />
                        <div className="text-red">{formik.touched.email && formik.errors.email}</div>
                    </div>

                    <div className="col-md-12">
                        <span className="form-label">Message</span>
                        <textarea
                            value={formik.values.message}
                            onChange={formik.handleChange('message')}
                            onBlur={formik.handleBlur('message')}
                            rows="5"
                            col="10"
                            type="text"
                            className="form-control"
                        />
                        <div className="text-red">{formik.touched.message && formik.errors.message}</div>
                    </div>
                    <div className="col-12 mt-3">
                        <button
                            type="submit"
                            className="btn main-button btn-lg send-btn"
                            disabled={loading || disableButton}
                        >
                            {loading ? 'Loading...' : 'Send Message'}
                        </button>
                        {disableButton === true && mailSent?.message === 'email sent' && (
                            <div className="result-sent mt-2">
                                <p>Message sent! We will get back to you soon.</p>
                            </div>
                        )}
                        <div className="error-message text-center">{appErr || serverErr}</div>
                    </div>
                </div>
            </form>
        </div>
    )
}
