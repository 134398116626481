import React from 'react'
import '../css/Homepage.css'
import About from '../components/About'
import Testimonials from '../components/Testimonials'
import PriceCards from '../components/PriceCards'
import { Link } from 'react-router-dom'

const Homepage = () => {
    return (
        <div className="homepage-container">
            <div className="container-fluid jumbotron-container">
                <div className="jumbotron-parallax">
                    <div className="slogan-bg"></div>
                    <div className="jumbotron-slogan">
                        <h1 data-aos="fade-up">Be in the know, before the guests show</h1>
                        <div className="join-btn" data-aos="fade-up" data-aos-delay="100">
                            <Link to="/selectplan" className="btn main-button btn-lg">
                                Join Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <About />
            <Testimonials />

            <PriceCards />
        </div>
    )
}

export default Homepage
