import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="py-4 bg-light">
            <div className="container">
                <div className="row g-0 align-items-center">
                    <div className="col-auto text-center">
                        <img src={require('../img/logo.png')} alt="logo" width={120} />
                    </div>
                    <div className="col text-muted">
                        <Link to="/termsofuse" className="text-muted">
                            Terms of Use
                        </Link>
                        <span className="mx-2">|</span>
                        <Link to="/privacypolicy" className="text-muted">
                            Privacy Policy
                        </Link>
                        <div className="mt-2">
                            &copy; {new Date().getFullYear()} All Rights Reserved
                            <span className="mx-2">|</span>
                            Rental86
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
