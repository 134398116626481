import React, { useState } from 'react'
import { Tabs, Tab, Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import ToastMessage from './Toast'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import constants from '../utils/constants'
import '../css/AdminDashboard.css'
import utility from '../utils/utility'

const columns = [
    {
        name: 'Email',
        selector: (row) => row.emailLink,
        sortable: true,
        width: '250px',
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Documents',
        selector: (row) => row.documents,
        sortable: true,
        width: '175px',
    },
    {
        name: 'URL',
        selector: (row) => row.propertyListing,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Tier',
        selector: (row) => constants.tiers.find((x) => x.id === row.tierId)?.name,
        sortable: true,
        width: '125px',
    },
    {
        name: 'Paid',
        selector: (row) => (row.currentlyPaid ? 'Yes' : 'No'),
        sortable: true,
        width: '90px',
    },
    {
        name: 'Signed up',
        selector: (row) => row.accountCreated,
        sortable: true,
        width: '140px',
    },
    {
        name: 'Actions',
        selector: (row) => row.actions,
    },
]

const reviewColumns = [
    {
        name: 'Reviewer',
        selector: (row) => row.reviewerName,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Guest',
        selector: (row) => row.guestName,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Text',
        selector: (row) => row.reviewElement,
        sortable: true,
        width: '250px',
    },
    {
        name: 'Files',
        selector: (row) => row.files,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Likes',
        selector: (row) => row.likes.length,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Dislikes',
        selector: (row) => row.dislikes.length,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Created',
        selector: (row) => row.createdAt,
        sortable: true,
        width: '130px',
    },
    {
        name: 'Actions',
        selector: (row) => row.actions,
    },
]

const AdminDashboard = () => {
    const [tableData, setTableData] = useState([])
    const [modalText, setModalText] = useState('')
    const [reviewsTableData, setReviewsTableData] = useState([])
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const users = useSelector((state) => state.users)
    const { userAuth } = users

    React.useEffect(() => {
        const toggleVerified = async (event, item, key) => {
            const data = {
                userId: item.id,
                newValues: {},
                sendEmail: event.target.checked,
                emailAddress: item.email,
                url: window.location.origin,
            }

            data.newValues[key] = event.target.checked

            const response = await fetch('/api/users/admin-update-user', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    Authorization: `Bearer ${userAuth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => res.json())

            if (response?.id) {
                let prefix = event.target.checked ? 'verified' : 'not verified'

                let suffix = ''

                if (key === 'isAccountVerified') {
                    suffix = event.target.checked ? ' and sent them a notification email' : ''
                } else if (key === 'isAdmin') {
                    prefix = event.target.checked ? 'an admin' : 'not an admin'
                }

                setMessage(`Set ${item.email} to ${prefix}${suffix}`)
            }
        }

        const toggleHidden = async (event, item) => {
            const data = {
                itemId: item.id,
                newValues: {
                    hiddenByAdmin: event.target.checked,
                },
            }

            const response = await fetch('/api/review/admin-update-review', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    Authorization: `Bearer ${userAuth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => res.json())

            if (response?.id) {
                const word = event.target.checked ? 'hidden' : 'show'

                setMessage(`Set ${item.review.slice(0, 50)}... to ${word}`)
            }
        }

        const fetchUsers = async () => {
            const response = await fetch('/api/users/', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userAuth?.token}`,
                },
            }).then((res) => res.json())

            if (!response?.length) {
                return
            }

            const newTableData = []

            for (let item of response) {
                item.emailLink = (
                    <a href={`mailto:${item.email}`} className="mx-1" target="_blank" rel="noreferrer">
                        {item.email}
                    </a>
                )

                item.accountCreated = item.accountCreated.slice(0, 10)

                item.name = item.firstName + ' ' + item.lastName
                let listings = [item.propertyListing, item.propertyListing2, item.propertyListing3]
                listings = listings.filter((x) => x?.length)

                const urls = listings.map((x) => {
                    const url = x.includes('://') ? item.propertyListing : `https://${item.propertyListing}`

                    return (
                        <a key={x} href={url} className="mx-1" target="_blank" rel="noreferrer">
                            Link
                        </a>
                    )
                })

                item.propertyListing = urls

                const documents = item.documents.map((x) => (
                    <a key={x} href={x} className="mx-1" target="_blank" rel="noreferrer">
                        Link
                    </a>
                ))

                item.documents = documents

                item.actions = (
                    <div className="py-3 text-start">
                        <Form.Check
                            type="switch"
                            className="custom-switch ms-1"
                            label="Admin Verified"
                            defaultChecked={item.isAccountVerified}
                            onChange={(event) => toggleVerified(event, item, 'isAccountVerified')}
                        />

                        <Form.Check
                            type="switch"
                            className="custom-switch ms-1"
                            label="Is Admin"
                            defaultChecked={item.isAdmin}
                            onChange={(event) => toggleVerified(event, item, 'isAdmin')}
                        />
                    </div>
                )

                newTableData.push(item)
            }

            setTableData(newTableData)

            setLoading(false)
        }

        const fetchReviews = async () => {
            const response = await fetch('/api/review?isAdminDashboard=1', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userAuth?.token}`,
                },
            }).then((res) => res.json())

            if (!response?.length) {
                return
            }

            const newTableData = []

            for (let item of response) {
                if (item.user?.firstName) {
                    item.reviewerName = item.user.firstName + ' ' + item.user.lastName
                }

                if (item.guest?.firstName) {
                    item.guestName = item.guest.firstName + ' ' + item.guest.lastName
                }

                item.reviewElement = (
                    <div role="button" onClick={() => setModalText(item.review)}>
                        {item.review}
                    </div>
                )

                item.createdAt = item.createdAt.slice(0, 10)

                const files = item.files?.map((x) => (
                    <a key={x} href={x} className="mx-1" target="_blank" rel="noreferrer">
                        Link
                    </a>
                ))

                item.files = files

                item.actions = (
                    <div className="py-3">
                        <Form.Check
                            type="switch"
                            className="custom-switch"
                            label="Hide"
                            defaultChecked={item.hiddenByAdmin}
                            onChange={(event) => toggleHidden(event, item)}
                        />
                    </div>
                )

                newTableData.push(item)
            }

            setReviewsTableData(newTableData)
        }

        fetchUsers()
        fetchReviews()
    }, [userAuth?.token])

    return (
        <div className="text-center admin-dashboard" style={{ minHeight: '70vh' }}>
            <ToastMessage message={message} setMessage={setMessage} bg="success" />

            <h3>Admin Dashboard</h3>

            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 justify-content-center">
                <Tab eventKey="home" title="Users">
                    <div className="container-fluid mt-3">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <DataTable columns={columns} data={tableData} progressPending={loading} />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="profile" title="Reviews">
                    <div className="container-fluid mt-3">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <DataTable columns={reviewColumns} data={reviewsTableData} progressPending={loading} />
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>

            <Modal show={modalText !== ''} onHide={() => setModalText('')} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Review text</Modal.Title>
                </Modal.Header>
                <Modal.Body>{utility.getLines(modalText)}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalText('')}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminDashboard
