import React from 'react'

const Learnmore = () => {
    return (
        <main>
            <div className="container px-md-5 my-5 p-5">
                <h3>About Us</h3>
                <div className="row">
                    <div className="col-md-12 mb-5">
                        <p>
                            After suffering a sizable business loss, at the hands of someone later proved to be a
                            practiced con artist, the idea of Rental86 was born. We, Dakota and Jamilah, the co-founders
                            of the website wanted to find a way to protect ourselves, and to help others who are doing
                            their best to run a good business and build a better future.
                        </p>
                        <p>
                            Many vacation rental owners are, by the nature of their work, the smallest of small business
                            owners. Major rental platforms have their bottom line to look after, and have stockholders
                            to please, these people aren’t always on your side simply due to the nature of a business
                            relationship. While there are millions of wonderful guests in the world, there are also
                            those who routinely cause problems and abuse the system. Most of the offenses never make it
                            into their reviews, nor into a court of law. Who wants to risk a cancel-worthy retaliatory
                            review, or spend hundreds on legal fees to replace a smashed antique worth $300?
                        </p>
                        <p>
                            The sad truth is that because we are the smallest of businesses, and we are also at the
                            mercy of our guests and their reviews, we have very little protection.
                        </p>
                        <p>
                            Rental86 is intended to be a knowledge-sharing platform where you are free to post factual
                            information about guests who surprised you. Whether it is a supportive review of somebody
                            who might initially be perceived as a threat; Or if it is a factual review of an incident
                            that caused damage to you or your business.
                        </p>
                        <p>
                            The ultimate goal of this platform is to identify serial-abusers of the system, and help
                            protect your business against them.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Learnmore
