//------------------------------------
// KEEP THIS
//------------------------------------

import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap'
import {
    AiOutlineArrowRight,
    AiOutlineCheck,
    AiOutlineClockCircle,
    AiOutlineInfoCircle,
    AiOutlinePlus,
    AiOutlineSearch,
} from 'react-icons/ai'
import { FaAngleUp } from 'react-icons/fa'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import '../../css/ViewReviews.css'
import { searchGuestsAction } from '../../redux/slices/guests/createGuestSlice'
import { fetchUserDetailsAction } from '../../redux/slices/users/usersSlices'
import constants from '../../utils/constants'
import { getQuotaString } from '../../utils/functions'
import utility from '../../utils/utility'

export default function GuestList() {
    const dispatch = useDispatch()
    const history = useHistory()
    const guest = useSelector((state) => state?.guest)
    const { guestList, loading, appErr, serverErr } = guest
    const users = useSelector((state) => state?.users)
    const { userAuth, userDetails } = users
    const [states] = React.useState({ '': '', ...constants.states })

    useEffect(() => {
        dispatch(fetchUserDetailsAction(userAuth?._id))
    }, [dispatch, userAuth?._id])

    const search = () => {
        let editedPhoneNumber = phoneNumber.replace(/\D/g, '')

        if (editedPhoneNumber?.length >= 11) {
            //ignore country code
            editedPhoneNumber = editedPhoneNumber.slice(2)
        }

        if (editedPhoneNumber.length < 10) {
            editedPhoneNumber = ''
        }

        const searchFilters = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phoneNumber: editedPhoneNumber,
            city: city.trim(),
            state: state.trim(),
            country,
        }

        dispatch(searchGuestsAction(searchFilters))

        setKey('search-result')
    }

    const hasFilter = () => {
        return firstName || lastName || phoneNumber?.length >= 10 || city || state
    }

    const viewReview = (guestId) => {
        history.push(`/guest/${guestId}`)
    }

    const hasViewed = (guestId) => {
        return userDetails?.views?.find((x) => x?._id === guestId)
    }

    const viewDate = (guestId) => {
        return userDetails?.views?.find((x) => x?._id === guestId)?.createdAt
    }

    const guestResultCard = (guest, isHistory) => {
        return (
            <div key={guest?._id} className="container" onClick={() => viewReview(guest?._id)}>
                <div className="review-container p-3">
                    <div className="row align-items-center">
                        <div className="col-md-6 py-2">
                            <div className="guest-review">
                                <h3 className="guest-name">
                                    {guest?.firstName} {guest?.lastName}
                                </h3>
                                <h5>***-***-{guest?.phoneNumber}</h5>
                                <h5>{utility.getLocation(guest)}</h5>
                                <time
                                    className="text-sm text-muted"
                                    title={
                                        isHistory
                                            ? 'Date you first viewed this guest'
                                            : 'Date guest was added to our database'
                                    }
                                >
                                    <AiOutlineClockCircle className="mr-2 mb-1" />
                                    <Moment format="MMM D YYYY">
                                        {isHistory ? viewDate(guest?._id) : guest?.createdAt}
                                    </Moment>
                                </time>
                            </div>
                        </div>
                        <div className="col-md-4 py-2">
                            <Link to={`/guest/${guest?._id}`} className="text-decoration-underline">
                                {isHistory || hasViewed(guest?._id) ? (
                                    <AiOutlineCheck className="mr-2 mb-1" />
                                ) : (
                                    <button className="btn btn-dark">
                                        <AiOutlineArrowRight className="mr-2 mb-1" /> View Guest
                                    </button>
                                )}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [phoneNumber, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')

    const resetForm = () => {
        setfirstName('')
        setlastName('')
        setPhone('')
        setCity('')
        setState('')
        setCountry('')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search()
        }
    }

    // constructor for Tabs/Tab compoents
    const [key, setKey] = useState('search-result')

    //scroll to top button
    const [showTopBtn, setShowTopBtn] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        })
    }, [])
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div className="container search-guest-container">
            <div className="row">
                <div className="col-md-4 search-guest-left">
                    <div className="px-3 search-guest-textbox">
                        <div className="mb-4 search-guest-title">
                            <h1>Find your guest</h1>{' '}
                            <p>
                                If you’re here to create a report about one of your guests, please start searching below
                                to see if you can add it to their already existing profile.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="firstname" className="form-label">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstname"
                            className="form-control"
                            placeholder="First name"
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="lastname" className="form-label">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastname"
                            className="form-control"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="phone" className="form-label">
                            Phone Number
                        </label>
                        <input
                            type="input"
                            id="phone"
                            minLength="7"
                            maxLength="17"
                            placeholder="000-000-0000"
                            className="form-control"
                            value={phoneNumber}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="city" className="form-label">
                            City
                        </label>
                        <input
                            type="text"
                            id="city"
                            className="form-control"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="state" className="form-label">
                            State
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        >
                            {Object.values(states).map((x) => (
                                <option key={x} value={x}>
                                    {x}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="country" className="form-label">
                            Country
                        </label>
                        <select
                            className="form-select"
                            aria-label="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            {constants.countries.map((x) => (
                                <option key={x.code} value={x.code}>
                                    {x.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-12 review-guest">
                        <button
                            className="btn main-button btn-lg w-100"
                            type="button"
                            disabled={loading || !hasFilter()}
                            onClick={search}
                        >
                            {loading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                        onClick={search}
                                    ></span>
                                    Loading...
                                </>
                            ) : (
                                <>
                                    <AiOutlineSearch className="mr-2 mb-1" />
                                    Search Guests
                                </>
                            )}
                        </button>

                        <button className="btn btn-lg main-button w-100 mt-1" type="button" onClick={resetForm}>
                            Reset Form
                        </button>
                    </div>
                </div>

                <div className="col-md-8 search-guest-right scroll">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="button-tooltip-2">
                                Each time you view a guest profile for the first time, you use one of your monthly
                                credits.
                            </Tooltip>
                        }
                    >
                        <div className="d-inline-block text-muted ms-1 mb-2">
                            <AiOutlineInfoCircle className="mr-2 mb-1" />
                            Your credit usage: {userDetails?.quotaUsed || 0} of {getQuotaString(userDetails?.tierId)}{' '}
                            profile views this month
                        </div>
                    </OverlayTrigger>
                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="search-result" title="Search Results" className="border">
                            {(appErr || serverErr) && appErr !== 'limitReached' && (
                                <p className="m-4 fs-5">{appErr || serverErr}</p>
                            )}
                            {!appErr && !serverErr && !guestList && (
                                <p className="text-muted m-4">Search results will appear here</p>
                            )}
                            {guestList?.length === 0 && <p className="text-muted m-4">No guests matched your search</p>}
                            {guestList?.map((guest) => guestResultCard(guest))}

                            {guestList && (
                                <div className="text-center review-guest">
                                    <div>Don't see the person you're looking for?</div>
                                    <Link to="/createguest">
                                        <div className="btn main-button btn-lg mb-3 mt-3" role="button">
                                            <AiOutlinePlus className="mr-2 mb-1" />
                                            Create Guest
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey="search-history" title="View History" className="border">
                            {!userDetails?.views?.length && (
                                <p className="text-muted m-4">Guest profiles you view will appear here</p>
                            )}
                            {userDetails?.views?.map((guest) => guestResultCard(guest, true))}
                        </Tab>
                    </Tabs>
                    <div className="top-to-btm">
                        {showTopBtn && <FaAngleUp className="icon-position icon-style" onClick={goToTop} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
