import React from 'react'
import Toast from 'react-bootstrap/Toast'

function ToastMessage(props) {
    return (
        <Toast
            className="position-fixed bottom-0 start-50 translate-middle"
            style={{ zIndex: 1 }}
            bg={props.bg || 'danger'}
            onClose={() => props?.setMessage('')}
            show={props?.message !== ''}
            autohide
            delay={4000}
        >
            <Toast.Body className="text-white z-1">{props?.message}</Toast.Body>
        </Toast>
    )
}

export default ToastMessage
