import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import ResetPassword from './components/account/ResetPassword'
import ResetPasswordForm from './components/account/ResetPasswordForm'
import UpdatePassword from './components/account/UpdatePassword'
import UserAccount from './components/account/UserAccount'
import Contact from './components/Contact'
import AdminDashboard from './components/AdminDashboard'
import AppLogout from './components/AppLogout'
import Bestpractices from './components/Bestpractices'
import Faq from './components/Faq'
import Footer from './components/Footer'
import Homepage from './components/Homepage'
import HostingHelp from './components/HostingHelp'
import { Latest2 } from './components/Latest2'
import LearnMore from './components/LearnMore'
import MemberHomepage from './components/MemberHomepage'
import Nav from './components/navigation/Nav'
import CreateReview from './components/post/CreateReview'
import PrivacyPolicy from './components/PrivacyPolicy'
import CreateGuest from './components/reviews/CreateGuest'
import GuestDetails from './components/reviews/GuestDetails'
import GuestList from './components/reviews/GuestList'
import AuthRoute from './components/route/AuthRoute'
import Scrolltotop from './components/ScrollToTop'
import SelectPlan from './components/SelectPlan'
import TermsOfUse from './components/TermsOfUse'
import Login from './components/users/Login'
import Register from './components/users/Register'
import { ViewReviews } from './components/ViewReviews'

const App = () => {
    return (
        <Router>
            <Nav />
            <Scrolltotop />
            <Switch>
                <Route exact path="/" component={Homepage} />
                <Route path="/faq" component={Faq} />
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/termsofuse" component={TermsOfUse} />
                <Route path="/test" component={CreateReview} />
                <Route path="/learnmore" component={LearnMore} />
                <Route path="/admin-dashboard" component={AdminDashboard} />
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
                <Route path="/selectplan" component={SelectPlan} />
                <Route path="/password-reset" component={ResetPasswordForm} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <Route path="/contact" component={Contact} />
                <AuthRoute path="/useraccount/:id" component={UserAccount} />
                {/* protected pages */}
                <AppLogout>
                    <AuthRoute path="/createguest" component={CreateGuest} />
                    <AuthRoute path="/bestpractices" component={Bestpractices} />
                    <AuthRoute exact path="/guestlist" component={GuestList} />
                    <AuthRoute path="/hostinghelp" component={HostingHelp} />
                    <AuthRoute path="/memberhomepage" component={MemberHomepage} />
                    <AuthRoute path="/updatePassword" component={UpdatePassword} />
                    <AuthRoute path="/view-more" component={Latest2} />
                    <AuthRoute path="/viewreviews" component={ViewReviews} />

                    <Route path="/guest/:id" component={GuestDetails} />
                </AppLogout>
                <Route path="/createguest" component={CreateGuest} />
            </Switch>
            <Footer />
        </Router>
    )
}

export default App
