import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updatePasswordAction } from '../../redux/slices/users/usersSlices'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import '../../css/UserAccount.css'

const formSchema = Yup.object({
    password: Yup.string().required('Password is required'),
})

const UpdatePassword = () => {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        onSubmit: (values) => {
            dispatch(updatePasswordAction(values?.password))

            console.log(values)
        },
        validationSchema: formSchema,
    })

    const users = useSelector((state) => state?.users)
    const { isUpdated, loading, appErr, serverErr, userAuth, passwordUpdated } = users
    // if (isUpdated) return <Redirect to="/useraccount" />;
    return (
        <div className="container password-input mt-4">
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="updatePassword">Password</label>
                <div className="input-group">
                    <input
                        value={formik.values.password}
                        onChange={formik.handleChange('password')}
                        onBlur={formik.handleBlur('password')}
                        type="password"
                        className="form-control"
                        placeholder="Enter new password"
                    />{' '}
                    <div className="text-red"> </div>
                </div>
                <div className=" message pt-2 ">
                    <button type="submit" className="btn btn-dark main-button mt-2" disabled={loading}>
                        {loading ? 'Loading...' : 'Update Password'}
                    </button>

                    {passwordUpdated && (
                        <div className="result-sent mt-2">
                            <p>Password updated</p>
                        </div>
                    )}

                    <div className="ErrorMessage text-center">
                        {appErr || serverErr ? (
                            <div className="error-message text-center">
                                {appErr || serverErr ? appErr || serverErr : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UpdatePassword
