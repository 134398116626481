import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { AiFillEye } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import '../../css/Register.css'
import { registerUserAction } from '../../redux/slices/users/usersSlices'
import constants from '../../utils/constants'
import UploadDocuments from '../account/UploadDocuments'
import ToastMessage from '../Toast'
import Payment from './Payment'

const formSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string()
        .required('Email is required')
        .matches(/^\S+@\S+\.\S+$/, 'Enter a valid email address'),
    phone: Yup.string().required('Phone is required'),
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Must Contain min 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
    address: Yup.string().required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().when('country', {
        is: (x) => x !== 'US',
        then: Yup.string().required('City is required'),
    }),
    zipcode: Yup.string().when('country', {
        is: 'US',
        then: Yup.string()
            .required('Zipcode is required')
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Enter a valid zipcode'),
    }),
    country: Yup.string().required('Country is required'),
    propertyListing: Yup.string().required('Listing is required'),
    propertyListing2: Yup.string(),
    propertyListing3: Yup.string(),
    documents: Yup.mixed().required('Documents are required'),
    insuranceExpirationDate: Yup.string().required('Please verify your insurance expiration date'),
})

const Register = () => {
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [passwordShown, setPasswordShown] = useState(false)
    const [loading, setLoading] = useState(false)
    const [agree, setAgree] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    }

    const checkboxHandler = () => {
        setAgree(!agree)
    }

    React.useEffect(() => {
        const parameters = new URLSearchParams(window.location.search)

        if (parameters.get('testData')) {
            setAgree(true)
        }

        if (parameters.get('coupon')) {
            localStorage.setItem('couponCode', parameters.get('coupon'))
        }
    }, [])

    const getInitialValues = () => {
        const parameters = new URLSearchParams(window.location.search)

        if (parameters.get('testData')) {
            return {
                firstName: 'test',
                lastName: 'test',
                title: 'test',
                email: 'account.test.2023.02.06.13.21.50@mt2015.com',
                password: '',
                phone: 'test',
                address: 'test',
                address2: 'test',
                city: '',
                zipcode: '11368',
                country: 'US',
                propertyListing: 'test',
                propertyListing2: '',
                propertyListing3: '',
                documents: [],
                insuranceExpirationDate: '2024-01-01',
                acceptedTerms: true,
                checked: [],
            }
        }

        return {
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            password: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            zipcode: '',
            country: 'US',
            propertyListing: '',
            propertyListing2: '',
            propertyListing3: '',
            documents: [],
            insuranceExpirationDate: '',
            acceptedTerms: true,
            checked: [],
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault()

        setLoading(true)

        // construct form data
        const formData = new FormData(event.target)

        Object.keys(formik.values).forEach((key) => {
            if (key !== 'insuranceExpirationDate') {
                formData.append(key, formik.values[key])
            }
        })

        const tierId = localStorage.getItem('tierId')

        formData.append('tierId', tierId)

        try {
            const response = await fetch('/api/users/register', {
                method: 'POST',
                body: formData,
            }).then((res) => res.json())

            if (response?._id) {
                dispatch(registerUserAction(response))
            } else {
                setErrorMessage(response.message)
            }
        } catch (error) {
            setErrorMessage('Something went wrong')
        }

        setLoading(false)
    }

    //Formik registration
    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: formSchema,
        onSubmit: (values) => {
            onSubmit()
        },
    })

    const storeData = useSelector((store) => store?.users)
    const { userAuth } = storeData

    if (userAuth?.currentlyPaid) {
        history.push('/memberhomepage')
        return null
    }

    return (
        <div className="container create-account-container">
            <ToastMessage message={message} setMessage={setMessage} />

            <div className="create-account-form" style={{ display: userAuth ? 'none' : 'block' }}>
                <div className="thankyou-message">
                    <h3>Sign Up</h3>
                    <h5>Please enter your details below</h5>
                </div>

                <form className="row g-3" encType="multipart/form-data" onSubmit={onSubmit}>
                    <h4>Your Information</h4>
                    <div className="form-group row g-2 create-account">
                        <div className="col-md-6">
                            <span className="form-label">First Name</span>
                            <input
                                value={formik.values.firstName}
                                onChange={formik.handleChange('firstName')}
                                onBlur={formik.handleBlur('firstName')}
                                required
                                type="firstName"
                                className="form-control"
                            />
                            <div className="text-red">{formik.touched.firstName && formik.errors.firstName}</div>
                        </div>
                        <div className="col-md-6">
                            <span className="form-label">Last Name</span>
                            <input
                                value={formik.values.lastName}
                                onChange={formik.handleChange('lastName')}
                                onBlur={formik.handleBlur('lastName')}
                                required
                                className="form-control"
                                type="lastName"
                            />
                            <div className="text-red">{formik.touched.lastName && formik.errors.lastName}</div>
                        </div>
                        <div className="col-md-6">
                            <span className="form-label">Job Title (Landlord, Manager, etc.)</span>
                            <input
                                value={formik.values.title}
                                onChange={formik.handleChange('title')}
                                onBlur={formik.handleBlur('title')}
                                required
                                className="form-control"
                                type="title"
                            />
                            <div className="text-red">{formik.touched.title && formik.errors.title}</div>
                        </div>
                        <div className="col-md-6">
                            <span className="form-label">Email</span>
                            <input
                                value={formik.values.email}
                                onChange={formik.handleChange('email')}
                                onBlur={formik.handleBlur('email')}
                                required
                                type="email"
                                className="form-control"
                            />
                            <div className="text-red">{formik.touched.email && formik.errors.email}</div>
                        </div>
                        <div className="col-md-6">
                            <span className="form-label">
                                Password
                                <button className="ms-1 text-muted border-0" type="button" onClick={togglePassword}>
                                    <AiFillEye />
                                </button>
                            </span>
                            <input
                                value={formik.values.password}
                                onChange={formik.handleChange('password')}
                                onBlur={formik.handleBlur('password')}
                                required
                                type={passwordShown ? 'text' : 'password'}
                                autoComplete="new-password"
                                className="form-control"
                            />

                            <div className="text-red">{formik.touched.password && formik.errors.password}</div>
                        </div>
                        <div className="col-md-6">
                            <span className="form-label">Phone Number</span>
                            <input
                                value={formik.values.phone}
                                onChange={formik.handleChange('phone')}
                                onBlur={formik.handleBlur('phone')}
                                required
                                type="phone"
                                className="form-control"
                            />
                            <div className="text-red">{formik.touched.phone && formik.errors.phone}</div>
                        </div>

                        <h4 className="mt-4">Property Information</h4>
                        <div className="col-md-12">
                            <span className="form-label">Address</span>
                            <input
                                value={formik.values.address}
                                onChange={formik.handleChange('address')}
                                onBlur={formik.handleBlur('address')}
                                required
                                type="address"
                                className="form-control"
                            />
                            <div className="text-red">{formik.touched.address && formik.errors.address}</div>
                        </div>
                        <div className="col-md-12">
                            <span className="form-label">Address Line 2 (optional)</span>
                            <input
                                value={formik.values.address2}
                                onChange={formik.handleChange('address2')}
                                onBlur={formik.handleBlur('address2')}
                                type="address"
                                className="form-control"
                            />
                        </div>
                        {formik.values.country !== 'US' && (
                            <div className="col-md-4">
                                <span className="form-label">City</span>
                                <input
                                    value={formik.values.city}
                                    onChange={formik.handleChange('city')}
                                    onBlur={formik.handleBlur('city')}
                                    required
                                    type="city"
                                    className="form-control"
                                />
                                <div className="text-red">{formik.touched.city && formik.errors.city}</div>
                            </div>
                        )}
                        {formik.values.country === 'US' && (
                            <div className="col-md-4">
                                <span className="form-label">Zipcode</span>
                                <input
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange('zipcode')}
                                    onBlur={formik.handleBlur('zipcode')}
                                    required
                                    type="zipcode"
                                    minLength={5}
                                    className="form-control"
                                />
                                <div className="text-red">{formik.touched.zipcode && formik.errors.zipcode}</div>
                            </div>
                        )}
                        <div className="col-md-4">
                            <span className="form-label">Country</span>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={formik.values.country}
                                onChange={formik.handleChange('country')}
                            >
                                {constants.countries.map((x) => (
                                    <option key={x.code} value={x.code}>
                                        {x.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <UploadDocuments formik={formik} />

                        <div className="col-md-8">
                            <span className="form-label">Property Listing URL's (Airbnb, VRBO, etc.)</span>
                            <input
                                value={formik.values.propertyListing}
                                onChange={formik.handleChange('propertyListing')}
                                onBlur={formik.handleBlur('propertyListing')}
                                required
                                type="propertyListing"
                                placeholder="https://www.airbnb.com/rooms/123456"
                                className="form-control"
                            />
                            <div className="text-red">
                                {formik.touched.propertyListing && formik.errors.propertyListing}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <input
                                value={formik.values.propertyListing2}
                                onChange={formik.handleChange('propertyListing2')}
                                onBlur={formik.handleBlur('propertyListing2')}
                                type="propertyListing2"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-8">
                            <input
                                value={formik.values.propertyListing3}
                                onChange={formik.handleChange('propertyListing3')}
                                onBlur={formik.handleBlur('propertyListing3')}
                                type="propertyListing3"
                                className="form-control"
                            />
                        </div>

                        <div className="col-md-8">
                            <div className="form-check mt-3">
                                <input
                                    type="checkbox"
                                    id="agree"
                                    className="form-check-input"
                                    required
                                    checked={agree}
                                    onChange={() => {}}
                                    onClick={checkboxHandler}
                                />
                                <label className="form-check-label" onClick={checkboxHandler} role="button">
                                    I accept the{' '}
                                    <a href="/termsofuse" className="text-decoration-underline">
                                        terms of use
                                    </a>{' '}
                                    and{' '}
                                    <a href="/privacypolicy" className="text-decoration-underline">
                                        privacy policy
                                    </a>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 px-1 mt-2">
                        <button type="submit" className="btn btn-lg btn-dark main-button mb-2 w-100" disabled={loading}>
                            {loading ? 'Loading...' : 'Create Account'}
                        </button>

                        <Alert variant="danger" style={{ opacity: errorMessage ? 1 : 0 }}>
                            <div className="text-center text-danger fw-bold">{errorMessage}</div>
                        </Alert>
                    </div>
                </form>
            </div>

            {userAuth && <Payment userAuth={userAuth} />}
        </div>
    )
}

export default Register
