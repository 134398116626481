import React from 'react'
import { Link } from 'react-router-dom'
import '../css/About.css'

const About = () => {
    return (
        <main>
            <div className="container-fluid about-container">
                <div className="row align-items-center">
                    <div className="col-lg-6 about-intro">
                        <h3 data-aos="fade-up" data-aos-delay="100">
                            Are you a vacation host?
                        </h3>
                        <p data-aos="fade-up" data-aos-delay="200">
                            The two greatest threats to a vacation property are damage to the house, and damage to the
                            review reputation. Rental86 is an added layer of warning, helping to protect your reputation
                            and your investment. For less than the cost of a single night’s rental income, you will have
                            access to thousands of detailed incident reports relating to prospective tenants before they
                            arrive on your doorstep.
                        </p>
                        <div className="join-btn">
                            <Link to="/learnmore" className="btn main-button btn-lg" role="button">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 about-video">
                        <div className="embed-container">
                            <div className="video-container" data-aos="fade-down">
                                <iframe
                                    src="https://www.youtube.com/embed/o_tgII0IyUo"
                                    title="video"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default About
