import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useHistory } from 'react-router-dom'
import constants from '../../utils/constants'
import CheckoutForm from './CheckoutForm'

export default function Payment(props) {
    const [clientSecret, setClientSecret] = React.useState('')
    const [stripePromise] = React.useState(initializeStripePromise)
    const history = useHistory()

    function initializeStripePromise() {
        let publishableKey = constants.stripeLivePublishableKey

        if (props.userAuth?.email.includes('account.test')) {
            console.log('using test key')
            publishableKey = constants.stripeTestPublishableKey
        }

        return loadStripe(publishableKey)
    }

    React.useEffect(() => {
        if (!props.userAuth?.paymentCustomerId) {
            return
        }

        const tierId = localStorage.getItem('tierId')

        if (!tierId) {
            history.push('/selectplan')
            return
        }

        const parameters = new URLSearchParams(window.location.search)

        const body = {
            emailAddress: props.userAuth?.email,
            customerId: props.userAuth?.paymentCustomerId,
            tierId,
            couponCode: parameters.get('coupon') || localStorage.getItem('couponCode'),
        }

        // Create PaymentIntent as soon as the page loads
        fetch('/api/users/create-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret))
    }, [props.userAuth?.email, props.userAuth?.paymentCustomerId, history])

    const appearance = {
        theme: 'stripe',
    }
    const options = {
        clientSecret,
        appearance,
    }

    return (
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    )
}
