import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AuthRoute = ({ component: Component, ...rest }) => {
    //check to see if user is logged in
    const user = useSelector((state) => state?.users)
    const { userAuth } = user

    return <Route {...rest} render={() => (userAuth ? <Component {...rest} /> : <Redirect to="/login" />)} />
}
export default AuthRoute
