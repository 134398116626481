import axios from 'axios'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import * as Redux from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { fetchUserDetailsAction, logoutAction, updateUserAction } from '../../redux/slices/users/usersSlices'
import constants from '../../utils/constants'
import UpdatePassword from '../account/UpdatePassword'
import { fetchReviewsAction } from '../../redux/slices/users/reviewSlices'
import ReviewList from '../reviews/ReviewList'
import UploadDocuments from './UploadDocuments'

const Result = () => {
    return (
        <div className="result-sent mt-2">
            <p>User information updated</p>
        </div>
    )
}

const formSchema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string(),
    email: Yup.string(),
    address: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string(),
    country: Yup.string(),
    insuranceExpirationDate: Yup.string(),
    propertyListing: Yup.string(),
})

const UserAccount = ({
    computedMatch: {
        params: { id },
    },
}) => {
    const dispatch = useDispatch()
    const review = Redux.useSelector((state) => state?.review)
    const { reviews, reviewsLoading } = review

    //fetch user profile
    useEffect(() => {
        dispatch(fetchUserDetailsAction(id))
        dispatch(fetchReviewsAction({ limit: 0, allReviewsByUser: 1 }))
    }, [dispatch, id])

    //User data from store
    const users = useSelector((state) => state.users)
    const { loading, appErr, serverErr, isUpdated, userDetails, userUpdated } = users

    const [uploadLoading, setUploadLoading] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('')
    const [uploadError, setUploadError] = useState('')
    const [result, showResult] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            phone: userDetails?.phone,
            email: userDetails?.email,
            address: userDetails?.address,
            address2: userDetails?.address2,
            zipcode: userDetails?.zipcode,
            country: userDetails?.country || 'US',
            city: userDetails?.city,
            state: userDetails?.state,
            propertyListing: userDetails?.propertyListing,
            propertyListing2: userDetails?.propertyListing2,
            propertyListing3: userDetails?.propertyListing3,
            insuranceExpirationDate: userDetails?.insuranceExpirationDate,
        },
        onSubmit: (values) => {
            dispatch(updateUserAction(values))
            showResult(true)
        },
        validationSchema: formSchema,
    })

    setTimeout(() => {
        showResult(false)
    }, 10 * 1000)

    const goToBillingPortal = async (e) => {
        e.preventDefault()

        const { userAuth } = users
        const config = {
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }

        const { data } = await axios.get(`/api/users/billing-portal`, config)

        if (data?.url) {
            window.location.href = data.url
        }

        return false
    }

    if (isUpdated) return <Redirect to={`/useraccount/${id}`} />

    const onSubmit = async (event) => {
        event.preventDefault()

        setUploadLoading(true)

        // construct form data
        const formData = new FormData(event.target)

        const { userAuth } = users

        const response = await fetch('/api/users/', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
            body: formData,
        }).then((res) => res.json())

        if (response?.id) {
            setUploadMessage('Uploaded successfully')
        } else {
            setUploadError(response.message)
        }

        setUploadLoading(false)
    }

    return (
        <div className="container">
            <div className="container settings-form-container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={4}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item className="tab-item">
                                    <Nav.Link eventKey="first">
                                        <h5>Personal Information</h5>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="tab-item">
                                    <Nav.Link eventKey="reviews">
                                        <h5>Reviews Posted</h5>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="tab-item">
                                    <Nav.Link eventKey="second" onClick={goToBillingPortal}>
                                        <h5>Manage Subscription & Billing Details</h5>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="tab-item">
                                    <Nav.Link onClick={() => dispatch(logoutAction())}>
                                        <h5>Log Out</h5>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <hr className="dropdown-divider mb-3" />
                        </Col>
                        <Col sm={8}>
                            <Tab.Content>
                                <Tab.Pane className="tab-pane" eventKey="first">
                                    <div className="selection-container">
                                        <h4 className="selection-title">Update Your Information</h4>
                                        <form className="mt-4" onSubmit={formik.handleSubmit}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputEmail4">First Name</label>
                                                    <input
                                                        value={formik.values.firstName}
                                                        onChange={formik.handleChange('firstName')}
                                                        onBlur={formik.handleBlur('firstName')}
                                                        type="firstName"
                                                        id="firstName"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputPassword4">Last Name</label>
                                                    <input
                                                        value={formik.values.lastName}
                                                        onChange={formik.handleChange('lastName')}
                                                        onBlur={formik.handleBlur('lastName')}
                                                        type="lastName"
                                                        id="lastName"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputAddress">Phone Number</label>
                                                    <input
                                                        value={formik.values.phone}
                                                        onChange={formik.handleChange('phone')}
                                                        onBlur={formik.handleBlur('phone')}
                                                        type="phone"
                                                        id="phone"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputAddress">Email</label>
                                                    <input
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange('email')}
                                                        onBlur={formik.handleBlur('email')}
                                                        type="email"
                                                        id="email"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div></div>
                                            <div className="form-group  ">
                                                <label htmlFor="inputCity">Address</label>
                                                <input
                                                    value={formik.values.address}
                                                    onChange={formik.handleChange('address')}
                                                    onBlur={formik.handleBlur('address')}
                                                    type="address"
                                                    id="address"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group ">
                                                <label htmlFor="inputCity">Address 2</label>
                                                <input
                                                    value={formik.values.address2}
                                                    onChange={formik.handleChange('address2')}
                                                    onBlur={formik.handleBlur('address2')}
                                                    type="address2"
                                                    id="address2"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-4 ">
                                                    <label htmlFor="inputCity">City</label>
                                                    <input
                                                        value={formik.values.city}
                                                        onChange={formik.handleChange('city')}
                                                        onBlur={formik.handleBlur('city')}
                                                        type="city"
                                                        id="city"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="inputState">State</label>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        value={formik.values.state}
                                                        onChange={formik.handleChange('state')}
                                                    >
                                                        {Object.values(constants.states).map((x) => (
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="inputZip">Zipcode</label>
                                                    <input
                                                        value={formik.values.zipcode}
                                                        onChange={formik.handleChange('zipcode')}
                                                        onBlur={formik.handleBlur('zipcode')}
                                                        type="zipcode"
                                                        id="zipcode"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="inputZip">Country</label>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        value={formik.values.country}
                                                        onChange={formik.handleChange('country')}
                                                    >
                                                        {constants.countries.map((x) => (
                                                            <option key={x.code} value={x.code}>
                                                                {x.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="inputEmail4">Property Listing: </label>
                                                    <input
                                                        value={formik.values.propertyListing}
                                                        onChange={formik.handleChange('propertyListing')}
                                                        onBlur={formik.handleBlur('propertyListing')}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="inputEmail4">Property Listing 2: </label>
                                                    <input
                                                        value={formik.values.propertyListing2}
                                                        onChange={formik.handleChange('propertyListing2')}
                                                        onBlur={formik.handleBlur('propertyListing2')}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="inputEmail4">Property Listing 3: </label>
                                                    <input
                                                        value={formik.values.propertyListing3}
                                                        onChange={formik.handleChange('propertyListing3')}
                                                        onBlur={formik.handleBlur('propertyListing3')}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="update-button pt-1 ">
                                                <button
                                                    type="submit"
                                                    className="btn btn-dark update-button main-button"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Loading...' : 'Update'}
                                                </button>

                                                {userUpdated && <div>{result ? <Result /> : null}</div>}
                                                <div className="ErrorMessage text-center">
                                                    {appErr || serverErr ? (
                                                        <div className="error-message text-center">
                                                            {appErr || serverErr ? appErr || serverErr : null}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </form>
                                        <UpdatePassword />

                                        <form
                                            className="row"
                                            action="/"
                                            encType="multipart/form-data"
                                            onSubmit={onSubmit}
                                        >
                                            <UploadDocuments formik={formik} />
                                            <div className="col-6">
                                                <button
                                                    type="submit"
                                                    className="btn btn-dark main-button mt-3"
                                                    disabled={uploadLoading}
                                                >
                                                    {uploadLoading ? 'Loading...' : 'Upload'}
                                                </button>
                                            </div>
                                        </form>

                                        {uploadMessage !== '' && <div className="text-center">{uploadMessage}</div>}

                                        <div className="ErrorMessage text-center">
                                            {uploadError !== '' && (
                                                <div className="error-message text-center">{uploadError}</div>
                                            )}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane" eventKey="reviews">
                                    <div className="selection-container">
                                        <div className="h3">Reviews Posted By Me</div>
                                        <div className="text-muted">
                                            Click a guest's name to go to their details page. There you can edit your
                                            review.
                                        </div>
                                        {reviewsLoading && <div>Loading...</div>}
                                        {!reviewsLoading && (
                                            <ReviewList
                                                allReviewsByUser
                                                review={reviews}
                                                onStartEditingReview={() => {}}
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="selection-container">
                                        <h4 className="selection-title pb-4">Manage Subscription</h4>
                                        <div>Going to subscription management page...</div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}

export default UserAccount
