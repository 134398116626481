import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import '../../css/CreateReview.css'
import constants from '../../utils/constants'

const formSchema = Yup.object({
    firstName: Yup.string()
        .required('First Name is required')
        .matches(/^\s*\S[\s\S]*$/, 'Please enter a valid first name'),
    lastName: Yup.string()
        .required('Last Name is required')
        .matches(/^\s*\S[\s\S]*$/, 'Please enter a valid first name'),
    phoneNumber: Yup.string().required('Phone is required').min(10, 'Phone number must be at least 10 characters'),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    review: Yup.string().required('A review is required'),
    reviewType: Yup.string().required('A review is required'),
})

const CreateReview = ({ guestId, createGuest, onPostReview, editingReview }) => {
    const review = useSelector((state) => state.review)
    const [loading, setLoading] = useState(false)
    const [states] = React.useState({ '': '', ...constants.states })
    const [errorMessage, setErrorMessage] = useState('')
    const { appErr, serverErr } = review
    const users = useSelector((state) => state.users)
    const { userAuth } = users
    const history = useHistory()

    const onSubmit = async (event) => {
        event.preventDefault()

        setLoading(true)

        // construct form data
        const formData = new FormData(event.target)

        formData.append('guestId', guestId)

        if (editingReview?._id) {
            formData.append('editingReviewId', editingReview?._id)
            formData.append('originalText', editingReview?.review)
        }

        Object.keys(formik.values).forEach((key) => {
            if (key === 'reviewType') {
                formData.set(key, formik.values[key])
            } else {
                formData.append(key, formik.values[key])
            }
        })

        const review = await fetch('/api/review', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
        }).then((res) => res.json())

        if (review?._id) {
            setErrorMessage('')

            if (guestId) {
                onPostReview?.()
                setLoading(false)
                window.scrollTo(0, 0)
            } else {
                history.push(`/guest/${review?.guest}`)
            }

            return
        }

        setErrorMessage(review.message)
        setLoading(false)
    }

    const getInitialValues = () => {
        const parameters = new URLSearchParams(window.location.search)

        if (parameters.get('testData')) {
            return {
                firstName: 'test',
                lastName: 'test',
                phoneNumber: '+1 123 123 1234',
                city: 'Test',
                state: 'New York',
                country: 'US',
                review: 'test review',
            }
        }

        return {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            city: '',
            state: '',
            country: 'US',
            review: '',
        }
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: (values, { resetForm }) => {
            onSubmit()
        },

        validationSchema: formSchema,
    })

    React.useEffect(() => {
        if (editingReview?.review) {
            console.log('effect', editingReview?.review)
            formik.setFieldValue('review', editingReview?.review)
            formik.setFieldValue('reviewType', editingReview?.reviewType)
        }
    }, [editingReview?.review])

    return (
        <div className="container review-guest-container">
            <form className="row g-3" onSubmit={onSubmit}>
                <div className="form-group row g-2 create-account">
                    {createGuest === true && (
                        <>
                            <div className="review-guest-header">
                                <h3 className="my-2">Create Guest</h3>
                                <p className="mt-4 mb-3">
                                    Had a surprising interaction with a guest? Good or bad, let the community know.
                                    Knowledge is strength, and it's our goal to keep entrepreneurs like you as strong
                                    and protected as we can.
                                </p>
                            </div>
                            <div className="form-group row g-2 create-account">
                                <div className="col-md-4">
                                    <label className="form-label">First Name</label>
                                    <input
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange('firstName')}
                                        onBlur={formik.handleBlur('firstName')}
                                        required
                                        type="firstName"
                                        className="form-control"
                                        placeholder="First Name"
                                    />
                                    <div className="text-red">
                                        {formik?.touched?.firstName && formik?.errors.firstName}
                                    </div>{' '}
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange('lastName')}
                                        onBlur={formik.handleBlur('lastName')}
                                        required
                                        type="lastName"
                                        className="form-control"
                                        placeholder="Last Name"
                                    />
                                    <div className="text-red">
                                        {formik?.touched?.lastName && formik?.errors.lastName}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange('phoneNumber')}
                                        onBlur={formik.handleBlur('phoneNumber')}
                                        placeholder="+1 123-123-1234"
                                        required
                                        minLength={10}
                                        className="form-control"
                                    />
                                    <div className="text-red">
                                        {formik?.touched?.phoneNumber && formik?.errors.phoneNumber}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">City</label>
                                    <input
                                        value={formik.values.city}
                                        onChange={formik.handleChange('city')}
                                        onBlur={formik.handleBlur('city')}
                                        type="city"
                                        className="form-control"
                                        placeholder="city"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">State</label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={formik.values.state}
                                        onChange={formik.handleChange('state')}
                                    >
                                        {Object.values(states).map((x) => (
                                            <option key={x} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Country</label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={formik.values.country}
                                        onChange={formik.handleChange('country')}
                                    >
                                        {constants.countries.map((x) => (
                                            <option key={x.code} value={x.code}>
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-md-12 mt-4">
                        {!createGuest && <h4>{editingReview ? 'Edit' : 'Create'} Review</h4>}
                        {createGuest === true && <h4 className="mb-3">Review</h4>}{' '}
                        <div className="text-red">{formik?.touched.incidentDate && formik?.errors.incidentDate}</div>
                        <div className="form-label">How was your experience with this person?</div>
                        <div className="d-flex align-items-center reviewType">
                            <Form.Check
                                inline
                                label="Positive"
                                name="reviewType"
                                type="radio"
                                required
                                value="1"
                                checked={formik.values.reviewType === '1'}
                                onChange={formik.handleChange('reviewType')}
                            />
                            <Form.Check
                                inline
                                label="Negative"
                                name="reviewType"
                                type="radio"
                                required
                                value="-1"
                                checked={formik.values.reviewType === '-1'}
                                onChange={formik.handleChange('reviewType')}
                            />
                        </div>
                        <label className="form-label mt-2">
                            Enter a factual statement about this surprising interaction
                        </label>
                        <textarea
                            className="form-control"
                            rows={5}
                            type="review"
                            required
                            value={formik.values.review}
                            placeholder="Type your review here"
                            onChange={formik.handleChange('review')}
                            onBlur={formik.handleBlur('review')}
                        ></textarea>
                        <div className="text-red">{formik.touched.review && formik.errors.review}</div>
                        <div className="form-text text-muted">
                            <div>
                                Please use common courtesy. Reviews with foul language and name calling will be deleted.
                                See{' '}
                                <Link to="/faq" className="text-decoration-underline">
                                    our FAQ
                                </Link>{' '}
                                for an example of a good incident report. We don't show your name next to your review
                                but if a lawsuit is brought against your review for slander (false accusation), your
                                information will be turned over to the proper authorities.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <span className="form-label fs-4">Upload Files</span>
                        <input
                            type="file"
                            className="form-control mt-3"
                            name="files"
                            multiple
                            accept=".gif,.jpg,.jpeg,.png,.pdf"
                        />
                    </div>
                    <div className="col-md-12 mt-2 px-3 text-muted">
                        <label className="form-label">
                            Images or PDF only. You can select multiple files in the file selection dialog. Upload
                            evidence such as photos, videos, police reports, screenshots, or other evidence showing the
                            guests' actions.
                        </label>
                    </div>
                    <div className="d-grid create-account-button mt-3">
                        <button type="submit" disabled={loading} className="btn btn-primary main-button">
                            {loading ? 'Loading...' : 'Submit'}
                        </button>
                    </div>
                    <Alert variant="danger" style={{ opacity: errorMessage ? 1 : 0 }}>
                        <div className="text-center text-danger fw-bold">{errorMessage}</div>
                    </Alert>
                    <div className="ErrorMessage text-red  text-center">
                        {appErr || serverErr ? (
                            <div className="error-message text-red  text-center">
                                {appErr || serverErr ? appErr || serverErr : null}
                            </div>
                        ) : null}
                    </div>{' '}
                </div>
            </form>
        </div>
    )
}

export default CreateReview
