import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../css/MemberHomepage.css'
import { Latest } from './Latest'
import { fetchUserDetailsAction } from '../redux/slices/users/usersSlices'
import { Link } from 'react-router-dom'

const Homepage = () => {
    const dispatch = useDispatch()
    const users = useSelector((state) => state?.users)
    const { userAuth, userDetails } = users

    useEffect(() => {
        dispatch(fetchUserDetailsAction(userAuth?._id))
    }, [dispatch, userAuth?._id])

    const loadedUser = () => {
        return userAuth && userDetails ? true : false
    }

    const isAccountVerified = () => {
        return userAuth?.isAccountVerified === true || userDetails?.isAccountVerified === true
    }

    const currentlyPaid = () => {
        return userAuth?.currentlyPaid === true || userDetails?.currentlyPaid === true
    }

    return (
        <div className="container-fluid homepage">
            <div className="row">
                <div className="col-md-6 homepage-main">
                    <div className="homepage-text">
                        <div className="homepage-title">
                            <h3>Hello {userAuth?.firstName}! </h3>
                        </div>
                        {loadedUser() === true && !currentlyPaid() && (
                            <div className="homepage-intro">
                                Your payment was not successful. Please try again at{' '}
                                <Link to="/register" className="text-decoration-underline">
                                    our payment page
                                </Link>
                                .
                            </div>
                        )}
                        {loadedUser() === true && !isAccountVerified() && (
                            <>
                                {currentlyPaid() && <div className="homepage-intro">Thanks for your payment!</div>}
                                <div className="homepage-intro">
                                    We'll email you at {userAuth?.email} when we're done verifying the documents you
                                    uploaded. It takes 1 to 3 business days. Then you can view and post reviews.
                                </div>
                            </>
                        )}
                        <div className="homepage-intro">
                            Thanks for being a part of Rental86, every report added to the platform makes both you and
                            your community stronger.
                        </div>

                        {isAccountVerified() && (
                            <div className="row homepage-buttons">
                                <div className="col-lg-12 search-guest ">
                                    <Link to="/guestlist" className="btn btn-lg main-button w-100" role="button">
                                        Search Guests
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-6 homepage-image" />
            </div>
            <Latest />
        </div>
    )
}

export default Homepage
