import React from 'react'
import '../css/PrivacyPolicy.css'
import policy from './files/PrivacyPolicy-v3.pdf'

const PrivacyPolicy = () => {
    return (
        <div className="container-fluid pp-container">
            <div className="pp-title">
                <h3>Privacy Policy</h3>
            </div>
            <div className="pp-body">
                <div className="pp-heading">
                    <h5>
                        <b>Introduction</b>
                    </h5>
                    <p>
                        Rental86 (<b>“Company”</b> or <b> “We”</b>) respect your privacy and are committed to protecting
                        it through our compliance with this policy.
                    </p>
                    <p>
                        {' '}
                        This policy describes the types of information we may collect from you or that you may provide
                        when you visit the website Rental86.com (our <b>“Website”</b>) and our practices for collecting,
                        using, maintaining, protecting, and disclosing that information.
                    </p>
                    This policy applies to information we collect:
                    <ul>
                        <li>On this Website</li>
                        <li>In email, text, and other electronic messages between you and this Website</li>
                        <li>
                            Through mobile and desktop applications you download from this Website, which provide
                            dedicated non-browser-based interaction between you and this Website
                        </li>
                        <li>
                            When you interact with our advertising and applications on third-party websites and
                            services, if those applications or advertising include links to this policy.
                        </li>
                    </ul>
                    It does not apply to information collected by:
                    <ul>
                        <li>
                            Us offline or through any other means, including on any other website operated by Company or
                            any third party; or
                        </li>
                        <li>
                            Any third party, including through any application or content (including advertising) that
                            may link to or be accessible from or on the Website.
                        </li>
                    </ul>
                    <p>
                        Please read this policy carefully to understand our policies and practices regarding your
                        information and how we will treat it. If you do not agree with our policies and practices, your
                        choice is not to use our Website. By accessing or using this Website, you agree to this privacy
                        policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your
                        continued use of this Website after we make changes is deemed to be acceptance of those changes,
                        so please check the policy periodically for updates.
                    </p>
                </div>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Children Under the Age of 18</b>
                </h5>
                <p>
                    Our Website is not intended for children under 18 years of age. No one under age 18 may provide any
                    information to or on the Website. We do not knowingly collect personal information from children
                    under 18. If you are under 18, do not use or provide any information on this Website or on or
                    through any of its features. If we learn we have collected or received personal information from a
                    child under 18 without verification of parental consent, we will delete that information. If you
                    believe we might have any information from or about a child under 18, please contact us at:{' '}
                    <a href="mailto: info@Rental86.com" className="pp-email">
                        <b>info@Rental86.com</b>
                    </a>
                </p>
                <p>
                    California residents under 18 years of age may have additional rights regarding the collection and
                    sale of their personal information. Please see Your California Privacy Rights for more information.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Information We Collect About You and How We Collect It </b>
                </h5>
                <p>
                    We collect several types of information from and about users of our Website, including information:{' '}
                </p>
                <ul>
                    <li>
                        By which you may be personally identified, such as name, postal address, e-mail address,
                        telephone number, or any other identifier by which you may be contacted online or offline (
                        <b>"personal information"</b> );
                    </li>
                    <li>That is about you, but individually does not identify you; and/or</li>
                    <li>
                        About your internet connection, the equipment you use to access our Website, and usage details
                    </li>
                </ul>
                We collect this information:
                <ul>
                    <li>Directly from you when you provide it to us</li>
                    <li>
                        Automatically as you navigate through the site. Information collected automatically may include
                        usage details, IP addresses, and information collected through cookies and other tracking
                        technologies.
                    </li>
                </ul>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Information You Provide to Us</b>
                </h5>
                <p>The information we collect on or through our Website may include:</p>
                <ul>
                    <li>
                        Information that you provide by filling in forms on our Website. This includes information
                        provided at the time of registering to use our Website, subscribing to our service, posting
                        material, or requesting further services. We may also ask you for information when you report a
                        problem with our Website.
                    </li>
                    <li>Records and copies of your correspondence (including email addresses), if you contact us. </li>
                    <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                    <li>Your search queries on the Website. </li>
                </ul>
                <p>
                    You also may provide information to be published or displayed (hereinafter, “<b>posted”</b> ) on
                    public areas of the Website, or transmitted to other users of the Website or third parties
                    (collectively, “<b>User Contributions</b>”). Your User Contributions are posted on and transmitted
                    to others at your own risk. Although we limit access to certain pages, please be aware that no
                    security measures are perfect or impenetrable. Additionally, we cannot control the actions of other
                    users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot
                    and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Information We Collect Through Automatic Data Collection Technologies</b>
                </h5>
                <p>
                    As you navigate through and interact with our Website, we may use automatic data collection
                    technologies to collect certain information about your equipment, browsing actions, and patterns,
                    including:
                </p>
                <ul>
                    <li>
                        Details of your visits to our Website, including traffic data, location data, logs, and other
                        communication data and the resources that you access and use on the Website.{' '}
                    </li>
                    <li>
                        Information about your computer and internet connection, including your IP address, operating
                        system, and browser type.
                    </li>
                </ul>
                <p>
                    The information we collect automatically may include personal information, or we may maintain it or
                    associate it with personal information we collect in other ways or receive from third parties. It
                    helps us to improve our Website and to deliver a better and more personalized service, including by
                    enabling us to:
                </p>
                <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>
                        Store information about your preferences, allowing us to customize our Website according to your
                        individual interests.
                    </li>{' '}
                    <li>Speed up your searches</li> <li>Recognize you when you return to our Website</li>{' '}
                </ul>{' '}
                <p>The technologies we use for this automatic data collection may include:</p>
                <ul>
                    <li>
                        <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your
                        computer. You may refuse to accept browser cookies by activating the appropriate setting on your
                        browser. However, if you select this setting you may be unable to access certain parts of our
                        Website. Unless you have adjusted your browser setting so that it will refuse cookies, our
                        system will issue cookies when you direct your browser to our Website.
                    </li>

                    <li>
                        <b>Flash Cookies.</b> Certain features of our Website may use local stored objects (or Flash
                        cookies) to collect and store information about your preferences and navigation to, from, and on
                        our Website. Flash cookies are not managed by the same browser settings as are used for browser
                        cookies. For information about managing your privacy and security settings for Flash cookies,
                        see Choices About How We Use and Disclose Your Information.
                    </li>
                </ul>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Third-Party Use of Cookies</b>
                </h5>
                <p>
                    Some content or applications, including advertisements, on the Website are served by third-parties,
                    including advertisers, ad networks and servers, content providers, and application providers. These
                    third parties may use cookies to collect information about you when you use our website. The
                    information they collect may be associated with your personal information or they may collect
                    information, including personal information, about your online activities over time and across
                    different websites and other online services. They may use this information to provide you with
                    interest-based (behavioral) advertising or other targeted content.
                </p>
                <p>
                    We do not control these third parties’ tracking technologies or how they may be used. If you have
                    any questions about an advertisement or other targeted content, you should contact the responsible
                    provider directly. For information about how you can opt out of receiving targeted advertising from
                    many providers, see Choices About How We Use and Disclose Your Information.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>How We Use Your Information </b>
                </h5>

                <p>
                    We use information that we collect about you or that you provide to us, including any personal
                    information:
                </p>
                <ul>
                    <li>To present our Website and its contents to you.</li>
                    <li>To provide you with information, products, or services that you request from us. </li>
                    <li>To fulfill any other purpose for which you provide it. </li>
                    <li>To provide you with notices about your account, including expiration and renewal notices. </li>
                    <li>
                        To carry out our obligations and enforce our rights arising from any contracts entered into
                        between you and us, including for billing and collection.
                    </li>
                    <li>
                        To notify you about changes to our Website or any products or services we offer or provide
                        though it.{' '}
                    </li>
                    <li>To allow you to participate in interactive features on our Website. </li>
                    <li>In any other way we may describe when you provide the information. </li>
                    <li>For any other purpose with your consent.</li>
                </ul>
                <p>
                    We may also use your information to contact you about goods and services that may be of interest to
                    you. If you do not want us to use your information in this way, please adjust your user preferences
                    in your account profile. For more information, see Choices About How We Use and Disclose Your
                    Information.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Disclosure of Your Information </b>
                </h5>
                <p>
                    We may disclose aggregated information about our users, and information that does not identify any
                    individual, without restriction.{' '}
                </p>

                <p>
                    We may disclose personal information that we collect or you provide as described in this privacy
                    policy:{' '}
                </p>
                <ul>
                    <li>To our subsidiaries and affiliates. </li>
                    <li>
                        To contractors, service providers, and other third parties we use to support our business and
                        who are bound by contractual obligations to keep personal information confidential and use it
                        only for the purposes for which we disclose it to them.
                    </li>
                    <li>
                        To a buyer or other successor in the event of a merger, divestiture, restructuring,
                        reorganization, dissolution, or other sale or transfer of some or all of Rental86’s assets,
                        whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                        which personal information held by Rental86 about our Website users is among the assets
                        transferred.
                    </li>
                    <li>
                        To fulfill the purpose for which you provide it. For example, if you give us an email address to
                        use the “email a friend” feature of our Website, we will transmit the contents of that email and
                        your email address to the recipients.
                    </li>
                    <li>For any other purpose disclosed by us when you provide the information. </li>
                    <li>With your consent. </li>
                </ul>
                <p>We may also disclose your personal information: </p>
                <ul>
                    <li>
                        To comply with any court order, law, or legal process, including to respond to any government or
                        regulatory request.{' '}
                    </li>
                    <li>
                        To enforce or apply our Terms of Use and other agreements, including for billing and collection
                        purposes.{' '}
                    </li>
                    <li>
                        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety
                        of Rental86, our customers, or others.{' '}
                    </li>
                </ul>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Choices About How We Use and Disclose Your Information </b>
                </h5>
                <p>
                    We strive to provide you with choices regarding the personal information you provide to us. We have
                    created mechanisms to provide you with the following control over your information:
                </p>
                <ul>
                    <li>
                        <b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all or some
                        browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies,
                        please note that some parts of this site may then be inaccessible or not function properly.
                    </li>
                    <li>
                        <b>Disclosure of Your Information for Third-Party Advertising.</b> If you do not want us to
                        share your personal information with unaffiliated or non-agent third parties for promotional
                        purposes, you can opt out by Email. You can also always opt out by sending us an email stating
                        your request to:
                        <a href="mailto: ioptout@rental86.com" className="pp-email">
                            <b>optout@rental86.com</b>
                        </a>
                        .
                    </li>
                    <li>
                        <b>Promotional Offers from the Company.</b> If you do not wish to have your contact information
                        used by the Company to promote our own or third parties’ products or services, you can opt out
                        by Email or at any other time by sending us an email stating your request to
                        optout@rental86.com. If we have sent you a promotional email, you may send us a return email
                        asking to be omitted from future email distributions.
                    </li>
                    <li>
                        <b>Targeted Advertising.</b> If you do not want us to use information that we collect or that
                        you provide to us to deliver advertisements according to our advertisers’ target-audience
                        preferences, you can opt out by Email or at any other time by sending us an email stating your
                        request to: <a href="mailto: ioptout@rental86.com" className="pp-email"></a>
                    </li>
                    <p>
                        We do not control third parties’ collection or use of your information to serve interest-based
                        advertising. However, these third parties may provide you with ways to choose not to have your
                        information collected or used in this way. You can opt out of receiving targeted ads from
                        members of the Network Advertising Initiative (<b>“NAI”</b>) on the NAI’s{' '}
                        <a href="https://thenai.org/">website</a>.
                    </p>
                </ul>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Accessing and Correcting Your Information </b>
                </h5>
                <p>
                    You can review and change your personal information by logging into the Website and visiting your
                    account profile page.{' '}
                </p>
                <p>
                    If you wish to delete your User Contributions from the Website, copies of your User Contributions
                    may remain viewable in cached and archived pages, or might have been copied or stored by other
                    Website users. Proper access and use of information provided on the Website, including User
                    Contributions, is governed by our Terms of Use. For the avoidance of doubt, though Users may delete
                    their Website account, User Contributions submitted by such User may remain available to be viewed
                    by other Users.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Changes to Our Privacy Policy </b>
                </h5>
                <p>
                    It is our policy to post any changes we make to our privacy policy on this page with a notice that
                    the privacy policy has been updated on the Website home page. If we make material changes to how we
                    treat our users’ personal information, we will notify you by email to the primary email address
                    specified in your account and/or through a notice on the Website home page. The date the privacy
                    policy was last revised is identified at the top of the page. You are responsible for ensuring we
                    have an up-to-date active and deliverable email address for you, and for periodically visiting our
                    Website and this privacy policy to check for any changes.
                </p>
            </div>
            <div className="pp-heading">
                <h5>
                    <b>Contact Information </b>
                </h5>
                <p>
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at:{' '}
                    <a href="mailto: info@Rental86.com" className="pp-email">
                        <b>info@Rental86.com</b>
                    </a>
                </p>
            </div>{' '}
            <div className="pp-footer-text">
                {' '}
                <a href={policy} download="PrivacyPolicy.pdf">
                    {' '}
                    <b>Download Policy</b>
                </a>
                <br />
                <i> Last modified: June 24, 2022</i>
            </div>{' '}
        </div>
    )
}

export default PrivacyPolicy
