import React from 'react'
import '../css/Latest.css'

export const ViewReviews = () => {
    return (
        <div className="container-fluid latest-container">
            <div className="l2-heading">
                <h3>The Latest and Greatest</h3>
                <div className="row">
                    <div className="col-md-5 col-md-offset-3">
                        <div className="testimonials">
                            <div className="active item">
                                <blockquote>
                                    <p>
                                        Denim you probably haven't heard of. Lorem ipsum dolor met consectetur
                                        adipisicing sit amet, consectetur adipisicing elit, of them jean shorts sed
                                        magna aliqua. Lorem ipsum dolor met.
                                    </p>
                                    <div className="carousel-info">
                                        <div className="pull-left">
                                            <span className="testimonials-post">January 13, 2021</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                            <div className="active item">
                                <blockquote>
                                    <p>
                                        Denim you probably haven't heard of. Lorem ipsum dolor met consectetur
                                        adipisicing sit amet, consectetur adipisicing elit, of them jean shorts sed
                                        magna aliqua. Lorem ipsum dolor met.
                                    </p>
                                    <div className="carousel-info">
                                        <div className="pull-left">
                                            <span className="testimonials-post">January 13, 2021</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                            <div className="active item">
                                <blockquote>
                                    <p>
                                        Denim you probably haven't heard of. Lorem ipsum dolor met consectetur
                                        adipisicing sit amet, consectetur adipisicing elit, of them jean shorts sed
                                        magna aliqua. Lorem ipsum dolor met.
                                    </p>
                                    <div className="carousel-info">
                                        <div className="pull-left">
                                            <span className="testimonials-post">January 13, 2021</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
