import React from 'react'
import PriceCards from './PriceCards'

export default function SelectPlan() {
    React.useEffect(() => {
        const parameters = new URLSearchParams(window.location.search)

        if (parameters.get('coupon')) {
            localStorage.setItem('couponCode', parameters.get('coupon'))
        }
    }, [])

    return (
        <main>
            <PriceCards />
        </main>
    )
}
