import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import '../../css/ResetPasswordForm.css'
import { useDispatch, useSelector } from 'react-redux'
import { passwordResetAction } from '../../redux/slices/users/usersSlices'

//Form schema
const formSchema = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Must Contain min 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
})

const ResetPassword = (props) => {
    const token = props.match.params.token
    const dispatch = useDispatch()
    //formik
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        onSubmit: (values) => {
            const data = {
                password: values?.password,
                token,
            }
            dispatch(passwordResetAction(data))
        },
        validationSchema: formSchema,
    })

    //select data from store
    const users = useSelector((state) => state?.users)
    const { passwordReset, loading, appErr, serverErr } = users

    //Redirect after new password

    useEffect(() => {
        setTimeout(() => {
            if (passwordReset) props.history.push('/login')
        }, 1000)
    }, [passwordReset])

    return (
        <div className="reset-password-form">
            <div className=" container reset-form">
                <div className="reset-password-title text-center">
                    <h3>Enter your new password</h3>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                    <div className="form-group reset">
                        {' '}
                        <label for="email">Password</label>
                        <input
                            type="password"
                            autoComplete="password"
                            value={formik.values.password}
                            onChange={formik.handleChange('password')}
                            onBlur={formik.handleBlur('password')}
                            className="form-control"
                        />
                    </div>
                    <div className="text-red"> {formik.touched.password && formik.errors.password}</div>
                    <div className="error-message text-center">{appErr || serverErr ? appErr || serverErr : null}</div>
                    <div className="success-message text-center">
                        {passwordReset && <p>Successful. You will be redirected to the login page.</p>}
                    </div>
                    <div>
                        {loading ? (
                            <button disabled className="btn btn-dark">
                                Loading...
                            </button>
                        ) : (
                            <div className="d-grid reset-button-container">
                                <button type="submit" className="btn btn-dark">
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword
